import { Map, fromJS } from 'immutable'
import cookies from 'react-cookies'
import { schema } from 'normalizr'
import humps from 'humps'

import { createReducer, AUTH_LOGOUT } from '_privateDependencies/react-utils/utils'
import { getInitialState } from '_utils/reducers'
import { COOKIES_OPTIONS, cookieUser } from '_utils/constants'
import { addUserCookies, removeUserCookies } from '_utils/helpers'
import {
  CANCEL_SUBSCRIPTION,
  START_SUBSCRIPTION,
  CHANGE_SUBSCRIPTION,
} from '_modules/payments/actions'
import { CREATE_PRESENTATION } from '_modules/presentations/actions'

import {
  AUTH_LOGIN,
  GET_USER,
  RESET_USER,
  UPDATE_USER,
  UPDATE_USER_LOGO,
  UPDATE_USER_PICTURE,
  UNAUTHORIZED,
  LOGIN_FACEBOOK,
  UNLINK_SOCIAL_ACCOUNT,
  LOGIN_GOOGLE,
  SIGN_UP_EMAIL,
  REFERRAL_CODE,
  UPDATE_REDUCER_USER,
  GET_REFERRAL_ROCK_CODE,
  CREATE_INTERCOM_CONTACT,
  GET_USER_ROLE,
  CREATE_TEAM_PAYMENT,
  GET_STRIPE_PORTAL_URL,
  GET_DONE_FOR_YOU_CHECKOUT_PAGE,
  GET_USER_INVITES,
  DECLINE_COMPANY_INVITE,
  ACCEPT_COMPANY_INVITE,
  COMPLETE_ONBOARDING,
  UPDATE_USER_HIDE_IN_FOLDER,
} from './actions'

export const userSchema = new schema.Entity('user')

export const INITIAL_STATE = getInitialState('user') || Map()

export const user = createReducer(
  INITIAL_STATE,
  {
    [AUTH_LOGIN.FULFILLED]: (state, { payload }) => {
      const payloadUser = payload.user
      const teste = { ...payloadUser, auto_summarize_assets: undefined }
      const cookiesUser = cookieUser(payloadUser)

      addUserCookies(cookiesUser, payload.key)

      return fromJS({
        ...teste,
        auth_token: payload.key,
        shouldLogout: false,
      })
    },

    [SIGN_UP_EMAIL.FULFILLED]: (state, { payload }) => {
      const payloadUser = payload.user
      const cookiesUser = cookieUser(payloadUser)

      addUserCookies(cookiesUser, payload.key)

      return fromJS({
        ...payloadUser,
        auth_token: payload.key,
        shouldLogout: false,
      })
    },

    [LOGIN_FACEBOOK.FULFILLED]: (state, { payload }) => {
      const payloadUser = payload.user
      const cookiesUser = cookieUser(payloadUser)

      addUserCookies(cookiesUser, payload.key)

      return fromJS({
        ...payloadUser,
        auth_token: payload.key,
        shouldLogout: false,
      })
    },

    [LOGIN_GOOGLE.FULFILLED]: (state, { payload }) => {
      const payloadUser = payload.user
      const cookiesUser = cookieUser(payloadUser)

      addUserCookies(cookiesUser, payload.key)
      return fromJS({
        ...payloadUser,
        auth_token: payload.key,
        shouldLogout: false,
      })
    },

    [UNLINK_SOCIAL_ACCOUNT.FULFILLED]: (state, { payload }) =>
      fromJS({
        ...payload,
        auth_token: state.get('auth_token'),
        shouldLogout: false,
      }),

    [AUTH_LOGOUT.FULFILLED]: () => {
      removeUserCookies()
      return Map()
    },

    [GET_USER.FULFILLED]: (state, { payload }) => {
      const cookiesUser = cookieUser(payload)
      addUserCookies(cookiesUser)

      return state.mergeDeep(fromJS({ ...payload }))
    },

    [RESET_USER]: () => {
      removeUserCookies()
      return Map()
    },
    [UPDATE_USER.PENDING]: (state, { meta }) => {
      const { isChangingAutoSummarizeAssets } = meta

      if (isChangingAutoSummarizeAssets) {
        const { payload } = meta
        return state
          .set('auto_summarize_assets', payload.auto_summarize_assets)
          .set('hide_summarizer_modal', payload.hide_summarizer_modal)
      }
      return state
    },
    [UPDATE_USER.FULFILLED]: (state, { payload, meta }) => {
      const cookiesUser = cookieUser(payload)
      const { isLoadingHideInFolder } = meta

      addUserCookies(cookiesUser)

      if (isLoadingHideInFolder) {
        return state.mergeDeep(fromJS({ ...payload, isLoadingHideInFolder: false }))
      }

      return state.mergeDeep(fromJS({ ...payload }))
    },

    [UPDATE_USER_LOGO.FULFILLED]: (state, { payload }) => state.mergeDeep(fromJS({ ...payload })),

    [UPDATE_USER_PICTURE.FULFILLED]: (state, { payload }) =>
      state.mergeDeep(fromJS({ ...payload })),

    [REFERRAL_CODE.FULFILLED]: state => {
      const data = cookies.load('user') || {}

      cookies.save('user', data, COOKIES_OPTIONS)
      return state.mergeDeep(fromJS({ ...data }))
    },

    [UNAUTHORIZED]: state => state.set('shouldLogout', true),

    [CANCEL_SUBSCRIPTION.FULFILLED]: (state, { payload }) =>
      state.mergeDeep(fromJS({ ...payload })),

    [START_SUBSCRIPTION.FULFILLED]: (state, { payload }) => state.mergeDeep(fromJS({ ...payload })),

    [CREATE_PRESENTATION.FULFILLED]: state => {
      if (!state.get('remaining_presentation_insights_free_user')) {
        return state
      }

      const remainingPresentations = state.get('remaining_presentation_insights_free_user') - 1
      return state.set('remaining_presentation_insights_free_user', remainingPresentations)
    },

    [CHANGE_SUBSCRIPTION.FULFILLED]: (state, { payload }) =>
      state.mergeDeep(fromJS({ ...payload })),

    [UPDATE_REDUCER_USER]: (state, { payload }) => state.mergeDeep(fromJS({ ...payload })),

    [GET_REFERRAL_ROCK_CODE.FULFILLED]: (state, { payload }) => {
      cookies.save('authUrl', payload.authUrl, { ...COOKIES_OPTIONS, maxAge: 86400 })
      return state.set('authUrl', { ...payload })
    },

    [CREATE_INTERCOM_CONTACT.FULFILLED]: (state, { meta }) => state.set('user_type', meta.userType),
    [GET_USER_ROLE.FULFILLED]: (state, { payload, meta }) =>
      state.set('userRole', { memberIndex: meta.memberIndex, role: payload.message }),
    [GET_USER_ROLE.PENDING]: state => state.set('userRole', undefined),
    [GET_USER_ROLE.REJECTED]: (state, { meta }) =>
      state.set('userRole', { memberIndex: meta.memberIndex }),
    [CREATE_TEAM_PAYMENT.PENDING]: state => state.set('checkoutUrl', undefined),
    [CREATE_TEAM_PAYMENT.FULFILLED]: (state, { payload }) => {
      const camelizedPayload = humps.camelizeKeys(payload)
      return state.set('checkoutUrl', camelizedPayload.checkoutUrl)
    },
    [GET_STRIPE_PORTAL_URL.PENDING]: state => state.set('stripePortalUrl', undefined),
    [GET_STRIPE_PORTAL_URL.FULFILLED]: (state, { payload }) => {
      const { url } = payload

      return state.set('stripePortalUrl', url)
    },
    [GET_DONE_FOR_YOU_CHECKOUT_PAGE.PENDING]: state =>
      state.set('doneForYouCheckoutUrl', undefined),
    [GET_DONE_FOR_YOU_CHECKOUT_PAGE.FULFILLED]: (state, { payload }) => {
      const { url } = payload

      return state.set('doneForYouCheckoutUrl', url)
    },
    [GET_USER_INVITES.FULFILLED]: (state, { payload }) =>
      state.mergeDeep(fromJS({ company_invites: payload })),
    [DECLINE_COMPANY_INVITE.FULFILLED]: (state, { meta }) =>
      state.update('company_invites', invites =>
        invites.filter(invite => invite.get('id') !== meta.inviteId)
      ),
    [ACCEPT_COMPANY_INVITE.FULFILLED]: (state, { payload, meta }) => {
      const cookiesUser = cookieUser(payload)
      addUserCookies(cookiesUser)

      return state
        .mergeDeep(fromJS({ ...payload }))
        .update('company_invites', invites =>
          invites.filter(invite => invite.get('id') !== meta.inviteId)
        )
    },
    [COMPLETE_ONBOARDING.FULFILLED]: state => state.set('userRole', { showOnboarding: false }),
    [UPDATE_USER_HIDE_IN_FOLDER.PENDING]: state => state.set('isLoadingHideInFolder', true),
    [UPDATE_USER_HIDE_IN_FOLDER.FULFILLED]: (state, { payload }) =>
      state.mergeDeep(fromJS({ ...payload })).set('isLoadingHideInFolder', false),
    [UPDATE_USER_HIDE_IN_FOLDER.REJECTED]: state => state.set('isLoadingHideInFolder', false),
  },
  true
)
