import React, { useMemo, useCallback, useReducer, useEffect, useRef, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import Spinner from 'react-spinkit'

import { Presentation } from '_models/'
import RepositionIcon from '_assets/icons/reposition-image.svg'
import ChangeIcon from '_assets/icons/screenshot.svg'
import ImagePositionIcon from '_assets/icons/image-position-icon.svg'
import { ALERT_TYPE } from '_components/toast'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import CoverEdit from '_components/cover-edit'
import MediaLibrary from '_components/media-library'
import WysiwygTextfield from '_components/wysiwyg-textfield'
import ImagePositionModal from '_components/presentation/presentation-header/image-position-modal'
import UserPicture from '_views/presentation-edit/user-picture'
import { GREY_88 } from '_constants/colors'
import { updatePresentation, updateCoverConversionPercentage } from '_modules/presentations/actions'
import { updatePresentationLoadingSelector } from '_modules/presentations/selectors'
import usePrevious from '_hooks/use-previous'
import AssetProgressBar, {
  PROGRESS_THEME,
} from '_components/asset/standard-asset/asset-progress-bar'
import { getPlainText, HIERARCHY_TYPES_ELEMENTS } from '_components/text-editor/utils'
import VideoPlayer from '_components/video-player'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { userSelector } from '_modules/user/selectors'
import ConfirmationModal from '_components/confirmation-modal'
import useModal from '_hooks/use-modal'

import styles from './styles.css'
import {
  reducer,
  TOGGLE_REPOSITION,
  UPDATE_STATE,
  TOGGLE_MEDIA_LIBRARY,
  TOGGLE_COVER_CHANGE,
} from './reducer'

const COVER_HEIGHT = 496

const DEFAULT_POSITION = {
  x: 0.5,
  y: 0.5,
}

const PresentationCoverSection = ({ presentation, isAIGenerated }) => {
  const user = useSelector(userSelector)
  const isUpdatingPresentation = useSelector(updatePresentationLoadingSelector)
  const wasUpdatingPresentation = usePrevious(isUpdatingPresentation)
  const previousPresentation = usePrevious(presentation)
  const isBrackgroundOrBackgroundCropped = presentation.background || presentation.backgroundCropped
  const [
    isConfirmationRemoveUserPictureModalOpen,
    onToggleConfirmationRemoveUserPictureModal,
  ] = useModal()

  const titleRef = useRef(null)
  const authorRef = useRef(null)
  const positionButtonRef = useRef(null)

  const initialState = useMemo(
    () => ({
      isRepositioningCover: false,
      isMediaLibraryModalOpen: false,
      isLoading: false,
      isCoverChanging: false,
      isColorCoverChanging: false,
      isPositionDropdownOpen: false,
      imagePositionDropdownPosition: {},
      backgroundOpacity: Number(presentation.backgroundOpacity),
      title: presentation.title || '',
      userName: getPlainText(presentation.userName)
        ? presentation.userName
        : `${user.get('first_name')} ${user.get('last_name')}`,
      picture: presentation.picture || user.get('picture'),
      picturePosition: presentation.picturePosition,
      createdByTextColor: presentation.createdByTextColor || 'rgba(255, 255, 255, 1)',
    }),
    [presentation, user]
  )

  const [state, localDispatch] = useReducer(reducer, initialState)

  const { width, height } = useWindowSize()
  const dispatch = useDispatch()

  const coverStyle = useMemo(() => {
    const whiteOpacity = state.backgroundOpacity - 0.4 < 0 ? 0 : state.backgroundOpacity - 0.4

    const OVERLAY = `linear-gradient(180deg, rgba(0, 0, 0,${state.backgroundOpacity}) 0%, rgba(0, 0, 0,${whiteOpacity}) 134.8%)`

    if (isBrackgroundOrBackgroundCropped) {
      return {
        backgroundImage: `${OVERLAY}, url(${presentation.backgroundCropped ||
          presentation.background})`,
      }
    }

    return {
      background: OVERLAY,
      backgroundColor: presentation.backgroundColor || GREY_88,
    }
  }, [
    state.backgroundOpacity,
    presentation.background,
    presentation.backgroundCropped,
    presentation.backgroundColor,
    isBrackgroundOrBackgroundCropped,
  ])

  const repositionOptions = useMemo(() => {
    const options = {
      initialPosition: DEFAULT_POSITION,
      initialScale: presentation.getIn(['backgroundMeta', 'scale']) || 1,
    }
    if (presentation.getIn(['backgroundMeta', 'position'])) {
      options.initialPosition = {
        x: presentation.getIn(['backgroundMeta', 'position', 'x']),
        y: presentation.getIn(['backgroundMeta', 'position', 'y']),
      }
    }

    return options
  }, [presentation])

  const onToggleRepositionCover = useCallback(() => {
    localDispatch({
      type: TOGGLE_REPOSITION,
    })
  }, [])

  const onToggleIsChangeFile = useCallback(() => {
    localDispatch({
      type: TOGGLE_COVER_CHANGE,
    })
  }, [])

  const setOpacity = useCallback(opacity => {
    localDispatch({
      type: UPDATE_STATE,
      payload: { backgroundOpacity: opacity },
    })
  }, [])

  const onRepositionCover = useCallback(
    ({ blob, position, scale, opacity }) => {
      const wasPositionChanged =
        JSON.stringify(position) !== JSON.stringify(repositionOptions.initialPosition)

      const wasScaleChanged = scale !== repositionOptions.initialScale

      const payload = {
        ...((wasPositionChanged || wasScaleChanged) && {
          backgroundCropped: blob,
          backgroundMeta: {
            position,
            scale,
          },
        }),
        backgroundOpacity: opacity,
      }

      dispatch(updatePresentation(presentation.id, payload))

      localDispatch({
        type: UPDATE_STATE,
        payload: { isLoading: true, backgroundOpacity: opacity },
      })
      onToggleRepositionCover()
    },
    [
      dispatch,
      onToggleRepositionCover,
      presentation.id,
      repositionOptions.initialPosition,
      repositionOptions.initialScale,
    ]
  )

  const getModalPosition = useCallback((element, setWith) => {
    const boundingClientRect = element.getBoundingClientRect()

    const elementWidth = boundingClientRect.width
    const yPosition = boundingClientRect.bottom + window.scrollY + 10
    const xPosition = boundingClientRect.left + (elementWidth - setWith) / 2

    return {
      top: `${yPosition}px`,
      left: `${xPosition}px`,
    }
  }, [])

  useLayoutEffect(() => {
    const imagePositionDropdownWidth = 178
    if (state.isPositionDropdownOpen && positionButtonRef && positionButtonRef.current) {
      const content = getModalPosition(positionButtonRef.current, imagePositionDropdownWidth)
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          imagePositionDropdownPosition: {
            content,
            overlay: {
              height: `${window.document.body.scrollHeight}px`,
            },
          },
        },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [width, height, getModalPosition, state.isPositionDropdownOpen])

  useEffect(() => {
    if (state.isColorCoverChanging && wasUpdatingPresentation && !isUpdatingPresentation) {
      localDispatch({
        type: UPDATE_STATE,
        payload: { isColorCoverChanging: !state.isColorCoverChanging },
      })
    }
  }, [isUpdatingPresentation, state.isColorCoverChanging, wasUpdatingPresentation])

  useEffect(() => {
    if (state.isLoading && wasUpdatingPresentation && !isUpdatingPresentation) {
      localDispatch({ type: UPDATE_STATE, payload: { isLoading: false } })
    }
  }, [isUpdatingPresentation, state.isLoading, wasUpdatingPresentation])

  useEffect(() => {
    if (presentation.picture && wasUpdatingPresentation && !isUpdatingPresentation) {
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          picture: presentation.picture,
        },
      })
    }
  }, [isUpdatingPresentation, wasUpdatingPresentation, presentation.picture])

  const onToggleMediaLibraryModal = useCallback(() => {
    localDispatch({ type: TOGGLE_MEDIA_LIBRARY })
  }, [])

  const onChangeCoverColor = useCallback(
    color => {
      const payload = {
        backgroundColor: color,
        background: null,
        backgroundCropped: null,
        backgroundMeta: {},
        backgroundVideoUrl: null,
      }

      dispatch(updatePresentation(presentation.id, payload))

      localDispatch({
        type: UPDATE_STATE,
        payload: { isColorCoverChanging: true },
      })
    },
    [dispatch, presentation.id]
  )

  const onChangeVideo = useCallback(
    videoUrl => {
      const payload = {
        backgroundVideoUrl: videoUrl,
        background: null,
        backgroundCropped: null,
        backgroundColor: null,
        backgroundMeta: {},
      }

      dispatch(updatePresentation(presentation.id, payload))

      localDispatch({
        type: UPDATE_STATE,
        payload: { isColorCoverChanging: true },
      })
    },
    [dispatch, presentation.id]
  )

  const onChangeCoverPicture = useCallback(
    (dataUrl, file) => {
      const payload = {
        background: file,
        backgroundCropped: null,
        backgroundMeta: {},
        backgroundColor: null,
        backgroundVideoUrl: null,
      }

      dispatch(updatePresentation(presentation.id, payload))
      localDispatch({
        type: UPDATE_STATE,
        payload: { isCoverChanging: true, isMediaLibraryModalOpen: false },
      })
    },
    [dispatch, presentation.id]
  )

  const onTogglePositionModal = useCallback(
    event => {
      const imagePositionDropdownWidth = 178
      const content = getModalPosition(event.currentTarget, imagePositionDropdownWidth)

      localDispatch({
        type: UPDATE_STATE,
        payload: {
          isPositionDropdownOpen: !state.isPositionDropdownOpen,
          imagePositionDropdownPosition: {
            content,
            overlay: {
              height: `${window.document.body.scrollHeight}px`,
            },
          },
        },
      })
    },
    [getModalPosition, state.isPositionDropdownOpen]
  )

  const onImagePositionChange = useCallback(
    event => {
      const { name } = event.currentTarget

      const payload = {
        picturePosition: name,
      }
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          ...payload,
          isPositionDropdownOpen: false,
        },
      })

      dispatch(updatePresentation(presentation.id, payload))
    },
    [dispatch, presentation.id]
  )

  const onInputChangeUpdatePresentation = useCallback(
    payload => {
      dispatch(updatePresentation(presentation.id, payload))
    },
    [dispatch, presentation.id]
  )

  const debouncedGetOrganizationsOnSearch = useDebouncedCallback(
    onInputChangeUpdatePresentation,
    600
  )

  const onInputChange = useCallback(
    ({ name, value, plainText, createdByTextColor = null }) => {
      const payload = {
        [name]: plainText ? value : plainText,
        ...(name === 'userName' && createdByTextColor && { createdByTextColor }),
      }
      if (name === 'title') {
        payload.cleanTitle = plainText
      }

      if (state[name] !== value) {
        localDispatch({ type: UPDATE_STATE, payload })
        debouncedGetOrganizationsOnSearch(payload)
      }
    },
    [debouncedGetOrganizationsOnSearch, state]
  )

  const onShowTitle = useCallback(() => {
    const payload = {
      titleIsHidden: false,
    }
    dispatch(updatePresentation(presentation.id, payload))
  }, [dispatch, presentation.id])

  const onHideTitle = useCallback(() => {
    const payload = {
      titleIsHidden: true,
    }
    dispatch(updatePresentation(presentation.id, payload))
  }, [dispatch, presentation.id])

  const onShowAuthor = useCallback(() => {
    const payload = {
      userNameIsHidden: false,
    }
    dispatch(updatePresentation(presentation.id, payload))
  }, [dispatch, presentation.id])

  const onHideAuthor = useCallback(() => {
    const payload = {
      userNameIsHidden: true,
    }
    dispatch(updatePresentation(presentation.id, payload))
  }, [dispatch, presentation.id])

  const onRemovePicture = useCallback(() => {
    if (presentation.picture === null) {
      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.ERROR,
          message: "Your default profile picture can't be removed.",
        })
      )
      onToggleConfirmationRemoveUserPictureModal()
      return
    }
    dispatch(
      updatePresentation(presentation.id, {
        picture: null,
      })
    )
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        picture: user.get('picture'),
      },
    })

    onToggleConfirmationRemoveUserPictureModal()
  }, [
    dispatch,
    presentation.id,
    presentation.picture,
    user,
    onToggleConfirmationRemoveUserPictureModal,
  ])

  const handleCoverClearProgress = useCallback(() => {
    const payload = { presentationId: presentation.id, percentage: undefined }
    dispatch(updateCoverConversionPercentage(payload))
  }, [dispatch, presentation.id])

  useEffect(() => {
    if (
      previousPresentation &&
      previousPresentation.get('percentageUpload') &&
      !presentation.get('percentageUpload') &&
      state.isCoverChanging
    ) {
      onToggleIsChangeFile()
    }
  }, [onToggleIsChangeFile, presentation, previousPresentation, state.isCoverChanging])

  if (state.isRepositioningCover) {
    return (
      <CoverEdit
        getHeight={COVER_HEIGHT}
        getWidth={width}
        image={isBrackgroundOrBackgroundCropped}
        initialPosition={repositionOptions.initialPosition}
        initialScale={repositionOptions.initialScale}
        onCancel={onToggleRepositionCover}
        onSave={onRepositionCover}
        opacity={state.backgroundOpacity}
        setOpacity={setOpacity}
      />
    )
  }

  return (
    <div
      className={classnames(styles['cover-wrapper'], {
        [styles['cover-wrapper-loading']]: state.isColorCoverChanging || state.isLoading,
      })}
    >
      {presentation.backgroundVideoUrl ? (
        <VideoPlayer backgroundVideoUrl={presentation.backgroundVideoUrl} />
      ) : (
        <div className={classnames(styles['cover-background'])} style={coverStyle} />
      )}
      {(state.isColorCoverChanging || state.isLoading) && (
        <Spinner className={styles.loading} color="#e82f72" fadeIn="none" name="cube-grid" />
      )}
      {state.isCoverChanging && presentation.get('percentageUpload') && (
        <AssetProgressBar
          className={styles['asset-file']}
          progressValue={presentation.get('percentageUpload')}
          onResend={onToggleIsChangeFile}
          handleClearProgress={handleCoverClearProgress}
          theme={PROGRESS_THEME.BLACK_OPACITY}
        />
      )}
      <div className={styles['cover-content']}>
        <div className={styles['cover-buttons']}>
          <Button
            onClick={onToggleMediaLibraryModal}
            size={BUTTON_SIZE.SMALL}
            theme={BUTTON_THEME.TRANSPARENT_FILL}
            startIcon={ChangeIcon}
            className={styles['change-button']}
          >
            {isBrackgroundOrBackgroundCropped ||
            presentation.backgroundColor ||
            presentation.backgroundVideoUrl
              ? 'Change'
              : 'Add'}
          </Button>
          {isBrackgroundOrBackgroundCropped && !presentation.backgroundVideoUrl && (
            <Button
              onClick={onToggleRepositionCover}
              size={BUTTON_SIZE.SMALL}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              startIcon={RepositionIcon}
              className={styles['edit-button']}
            >
              Edit
            </Button>
          )}
        </div>
        <WysiwygTextfield
          value={state.title}
          name="title"
          placeholder="Add title"
          onChange={onInputChange}
          className={classnames({
            [styles['presentation-title-hidden']]: presentation.titleIsHidden,
          })}
          inputClassName={classnames(styles['presentation-title'], {
            [styles['presentation-title-input-hidden']]: presentation.titleIsHidden,
          })}
          isHidden={presentation.titleIsHidden}
          hasHideAction
          ref={titleRef}
          onShowClick={onShowTitle}
          onHideClick={onHideTitle}
          maxLength={170}
          isLoading={isUpdatingPresentation}
          textAlignment={state.picturePosition}
          presentationId={presentation.id}
          allowColorPicker
          type={HIERARCHY_TYPES_ELEMENTS.TITLE}
        />
        <div
          className={classnames(
            styles['author-container'],
            styles[`author-container-${state.picturePosition}`]
          )}
        >
          <UserPicture
            picture={state.picture}
            className={styles['user-picture']}
            presentationId={presentation.id}
            onRemove={onToggleConfirmationRemoveUserPictureModal}
          />
          <span
            style={{
              color: state.createdByTextColor,
            }}
            className={styles['presentation-author-title']}
          >
            Created by
          </span>
          <WysiwygTextfield
            value={state.userName}
            name="userName"
            className={classnames(styles['presentation-author'], {
              [styles['presentation-author-hidden']]: presentation.userNameIsHidden,
            })}
            inputClassName={classnames(styles['presentation-author-input'], {
              [styles['presentation-author-input-hidden']]: presentation.userNameIsHidden,
            })}
            onChange={onInputChange}
            hasHideAction
            ref={authorRef}
            onShowClick={onShowAuthor}
            onHideClick={onHideAuthor}
            isHidden={presentation.userNameIsHidden}
            maxLength={100}
            isLoading={isUpdatingPresentation}
            allowColorPicker
            textAlignment={state.picturePosition}
          />

          <Button
            onClick={onTogglePositionModal}
            size={BUTTON_SIZE.SMALL}
            theme={BUTTON_THEME.TRANSPARENT_FILL}
            startIcon={ImagePositionIcon}
            className={styles['position-button']}
            ref={positionButtonRef}
          >
            Position
          </Button>
        </div>
      </div>
      {state.isMediaLibraryModalOpen && (
        <MediaLibrary
          isOpen
          dismiss={onToggleMediaLibraryModal}
          changeColor={onChangeCoverColor}
          changeVideo={onChangeVideo}
          selectFile={onChangeCoverPicture}
          onToggleIsChangeFile={onToggleIsChangeFile}
          presentationId={presentation.id}
          backgroundVideoUrl={presentation.backgroundVideoUrl}
          {...(isAIGenerated && {
            presentationTitle: presentation.cleanTitle,
          })}
        />
      )}
      {state.isPositionDropdownOpen && (
        <ImagePositionModal
          isOpen
          position={state.picturePosition}
          dismiss={onTogglePositionModal}
          style={state.imagePositionDropdownPosition}
          onPositionClick={onImagePositionChange}
          // showNoPictureOption={false}
        />
      )}
      {isConfirmationRemoveUserPictureModalOpen && (
        <ConfirmationModal
          title="Do you want remove your picture?"
          description="Would you like to remove your picture from the presentation? Your profile picture will be displayed instead."
          isOpen={isConfirmationRemoveUserPictureModalOpen}
          isClosable
          onCloseText="No, don't remove"
          onFinishText="Yes, remove it"
          onClose={onToggleConfirmationRemoveUserPictureModal}
          onFinish={onRemovePicture}
          modalClassNames={styles['remove-user-presentation-modal']}
        />
      )}
    </div>
  )
}

PresentationCoverSection.propTypes = {
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  isAIGenerated: PropTypes.string,
}

PresentationCoverSection.defaultProps = {
  isAIGenerated: undefined,
}

export default React.memo(PresentationCoverSection)
