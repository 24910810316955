import React, { useCallback, useMemo, useReducer, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import classnames from 'classnames'

import AssetVisualizationModal from '_components/presentation-preview/presentation-content-preview/asset-visualization-modal'
import useToggle from '_hooks/use-modal'
import { getPresentationAssetByIdSelector } from '_modules/assets/selectors'
import PreviewAssetModal from '_components/preview-asset-modal-new'
import {
  clearProgress,
  editPresentationAsset,
  editAssetUploadSystemV2,
  EDIT_ASSET_UPLOAD_SYSTEM_V2,
  ASSET_FILE_UPLOAD_CONVERT,
} from '_modules/assets/actions'
import { validateUrl, createUrlPayload } from '_utils/helpers'
import { ASSET_TYPE } from '_constants/presentation'
import { presentationSelector } from '_modules/presentations/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import { autoSummarizeAssetsSelector, hideSummarizerModalSelector } from '_modules/user/selectors'
import { FLAG_ACTIVE_SUMMARIZER } from '_config/environment'
import AutoSummarizerModal from '_components/auto-summarizer-modal'
import usePrevious from '_hooks/use-previous'
import useSendFile from '_hooks/use-send-file'

import AssetMoreActions from '../components/asset-more-actions'
import HideAsset from '../components/hide-asset'
import DraggableWrapper from '../components/draggable-wrapper'

import { reducer, INITIAL_STATE, UPDATE_STATE, TAB_NAVIGATION } from './reducer'
import styles from './styles.css'
import AssetProgressBar from './asset-progress-bar'
import AssetFileContainer from './asset-file-container'
// eslint-disable-next-line import/no-cycle
import AssetInsertFileContainer from './asset-insert-file-container'
import AssetTextContainer from './asset-text-container'

export const AssetContext = React.createContext()

export const StandardAsset = ({
  index,
  assetId,
  presentationId,
  onToggleChangeImage,
  isAIGenerated,
  handleAssetInputChange,
}) => {
  const { sendFile } = useSendFile()
  const asset = useSelector(state => getPresentationAssetByIdSelector(state, assetId))
  const presentation = useSelector(reducerState =>
    presentationSelector(reducerState, presentationId)
  )

  const prevAssetPercentageUpload = usePrevious(asset.get('percentageUpload'))

  const isAutoSummarizeAssets = useSelector(autoSummarizeAssetsSelector)
  const hideSummarizerModal = useSelector(hideSummarizerModalSelector)

  const getInitialState = () => {
    if (asset.get('type') === ASSET_TYPE.LINK || asset.get('type') === ASSET_TYPE.VIDEO) {
      return { ...INITIAL_STATE, link: asset.get('website') || '', activeTab: asset.get('type') }
    }

    if (FLAG_ACTIVE_SUMMARIZER) {
      return {
        ...INITIAL_STATE,
        summarizeAsset: isAutoSummarizeAssets,
        isAutoSummarizeAssets,
        hideSummarizerModal,
        agreeShareThirdPartySummarizer: false,
      }
    }

    return INITIAL_STATE
  }

  const [state, localDispatch] = useReducer(reducer, getInitialState())

  const [isChangeFile, onToggleIsChangeFile] = useToggle()
  const [isPreviewAssetModalOpen, onTogglePreviewAssetModal] = useToggle()
  const [isPreviewAssetContentModalOpen, onTogglePreviewAssetContentModalOpen] = useToggle()
  const [isAutoSummarizeModal, onToggleAutoSummarizeModal] = useToggle()

  const dispatch = useDispatch()

  const onResend = useCallback(() => {
    onToggleIsChangeFile(true)
    dispatch(clearProgress(assetId))
  }, [onToggleIsChangeFile, dispatch, assetId])

  const clearState = useCallback(() => {
    localDispatch({ type: UPDATE_STATE, payload: INITIAL_STATE })
  }, [localDispatch])

  useFetchCall(ASSET_FILE_UPLOAD_CONVERT, clearState)

  const handleSendFile = useCallback(() => {
    if (asset.get('isLoadingS3')) return
    const useSummarizeAsset = state.summarizeAsset || state.isAutoSummarizeAssets

    const payload = {
      keep_current_thumbnail: state.keepCurrentThumbnail,
      ...(state.isThumbnailOnly && { is_thumbnail_only: state.isThumbnailOnly }),
      ...(state.thumbnailMeta && {
        is_thumbnail_cropped: true,
        thumbnail_meta: state.thumbnailMeta,
      }),
      ...(FLAG_ACTIVE_SUMMARIZER && {
        summarize_asset: useSummarizeAsset,
        auto_summarize_assets: state.isAutoSummarizeAssets,
        hide_summarizer_modal: state.hideSummarizerModal,
      }),
    }
    const fields = asset.get('fields')
    if (fields) {
      sendFile(asset, presentationId, state.file, payload)
    }
  }, [asset, presentationId, sendFile, state])

  useFetchCall(EDIT_ASSET_UPLOAD_SYSTEM_V2, handleSendFile)

  const onSelectFileUploadSystemV2 = useCallback(
    file => {
      const payload = {
        filename: file.name,
        useV2: true,
      }

      dispatch(
        editAssetUploadSystemV2({
          presentationId,
          id: assetId,
          payload,
        })
      )
    },
    [assetId, presentationId, dispatch]
  )

  const onSelectFile = useCallback(
    (file, keepCurrentThumbnail = false) => {
      if (state.activeTab === TAB_NAVIGATION.FILE) {
        localDispatch({
          type: UPDATE_STATE,
          payload: {
            file,
            keepCurrentThumbnail,
          },
        })

        if (FLAG_ACTIVE_SUMMARIZER && !isAutoSummarizeAssets && !hideSummarizerModal) {
          onToggleAutoSummarizeModal()
          return
        }

        onSelectFileUploadSystemV2(file)
        return
      }

      if (state.activeTab === TAB_NAVIGATION.LINK) {
        if (!validateUrl(state.link)) {
          localDispatch({ type: UPDATE_STATE, payload: { link: '' } })
          return
        }
        const params = presentation.get('template')
          ? { template: presentation.get('template') }
          : null

        const payload = {
          [TAB_NAVIGATION.LINK]: state.link && createUrlPayload(state.link),
        }

        dispatch(
          editPresentationAsset({
            id: asset.get('id'),
            presentationId: presentation.get('id'),
            params,
            payload: {
              ...payload[state.activeTab],
              ...(keepCurrentThumbnail && { keepCurrentThumbnail }),
            },
            isUploadingFile: true,
          })
        )
      }
    },
    [
      state.activeTab,
      state.link,
      presentation,
      dispatch,
      asset,
      localDispatch,
      onSelectFileUploadSystemV2,
      onToggleAutoSummarizeModal,
      isAutoSummarizeAssets,
      hideSummarizerModal,
    ]
  )

  const onRepositionCover = useCallback(
    ({ blob, position, scale, name }) => {
      const payloadV2 = {
        filename: name,
        useV2: true,
        isThumbnailCropped: true,
      }

      localDispatch({
        type: UPDATE_STATE,
        payload: {
          file: blob,
          thumbnailMeta: {
            position,
            scale,
          },
        },
      })

      dispatch(
        editAssetUploadSystemV2({
          presentationId,
          id: asset.get('id'),
          payload: payloadV2,
        })
      )
    },
    [asset, dispatch, presentationId]
  )

  const onChangeCover = useCallback(
    file => {
      const payloadV2 = {
        filename: asset.get('thumbnail'),
        useV2: true,
        isThumbnailOnly: true,
      }

      localDispatch({
        type: UPDATE_STATE,
        payload: {
          isThumbnailOnly: true,
          file,
        },
      })

      dispatch(
        editAssetUploadSystemV2({
          presentationId,
          id: asset.get('id'),
          payload: payloadV2,
        })
      )
    },
    [asset, dispatch, presentationId]
  )

  const handleConfirmationAI = useCallback(() => {
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        summarizeAsset: true,
      },
    })
    onSelectFileUploadSystemV2(state.file)
  }, [state.file, localDispatch, onSelectFileUploadSystemV2])

  const handleRejectionAI = useCallback(() => {
    localDispatch({
      type: UPDATE_STATE,
      payload: {
        summarizeAsset: false,
      },
    })

    onSelectFileUploadSystemV2(state.file)
  }, [state.file, onSelectFileUploadSystemV2])

  const renderAssetFile = useMemo(() => {
    if (asset.get('percentageUpload') || asset.get('hasError')) {
      return (
        <AssetProgressBar
          assetId={asset.get('id')}
          className={styles['asset-file']}
          progressValue={asset.get('percentageUpload')}
          onResend={onResend}
        />
      )
    }

    if ((asset.get('thumbnail') || asset.get('thumbnailCropped')) && !isChangeFile) {
      return (
        <AssetFileContainer
          asset={asset}
          presentationId={presentationId}
          className={styles['asset-file']}
          onChange={onToggleIsChangeFile}
          onPreview={onTogglePreviewAssetModal}
          onToggleChangeImage={onToggleChangeImage}
          isAIGenerated={isAIGenerated}
          onChangeCover={onChangeCover}
          onRepositionCover={onRepositionCover}
        />
      )
    }

    return (
      <AssetInsertFileContainer
        isChangeFile={isChangeFile}
        asset={asset}
        className={styles['asset-file']}
        presentationId={presentationId}
        onDismiss={onToggleIsChangeFile}
        isAIGenerated={isAIGenerated}
        onSelectFile={onSelectFile}
        state={state}
        localDispatch={localDispatch}
      />
    )
  }, [
    onSelectFile,
    asset,
    isAIGenerated,
    isChangeFile,
    onResend,
    onToggleChangeImage,
    onToggleIsChangeFile,
    onTogglePreviewAssetModal,
    presentationId,
    state,
    localDispatch,
    onRepositionCover,
    onChangeCover,
  ])

  const onHandleCheckboxChange = useCallback((name, checked) => {
    localDispatch({ type: UPDATE_STATE, payload: { [name]: checked } })
  }, [])

  useEffect(() => {
    if (
      window &&
      window.posthog &&
      prevAssetPercentageUpload &&
      !asset.get('percentageUpload') &&
      !asset.get('hasError') &&
      state.file
    ) {
      window.posthog.capture('FileUploadSuccessful', {
        file_size: state.file.size,
      })
    }
  }, [asset, state.file, prevAssetPercentageUpload])

  return (
    <DraggableWrapper draggableId={String(asset.get('id'))} index={index}>
      <div className={styles['asset-wrapper']}>
        <div
          className={classnames(styles.card, {
            [styles.hidden]: asset.get('isHidden'),
          })}
        >
          {asset.get('isHidden') && <HideAsset asset={asset} presentationId={presentationId} />}
          {renderAssetFile}
          <AssetTextContainer
            asset={asset}
            className={styles['asset-text']}
            presentationId={presentationId}
            handleAssetInputChange={handleAssetInputChange}
          />
          <AssetMoreActions
            asset={asset}
            className={styles['asset-actions']}
            presentationId={presentationId}
            onPreview={onTogglePreviewAssetModal}
          />
        </div>
      </div>

      {isPreviewAssetModalOpen && (
        <PreviewAssetModal
          assetId={asset.get('id')}
          presentationId={presentationId}
          onClose={onTogglePreviewAssetModal}
        />
      )}
      {isPreviewAssetContentModalOpen && (
        <AssetVisualizationModal
          isOpen
          dismiss={onTogglePreviewAssetContentModalOpen}
          assets={List().push(asset)}
          isSingleAssetPreview
        />
      )}
      {isAutoSummarizeModal && !hideSummarizerModal && (
        <AutoSummarizerModal
          isOpen={isAutoSummarizeModal}
          onClose={onToggleAutoSummarizeModal}
          onConfirm={handleConfirmationAI}
          onReject={handleRejectionAI}
          onHandleCheckboxChange={onHandleCheckboxChange}
          agreeShareThirdPartySummarizer={state.agreeShareThirdPartySummarizer}
        />
      )}
    </DraggableWrapper>
  )
}

StandardAsset.propTypes = {
  assetId: PropTypes.number.isRequired,
  presentationId: PropTypes.number.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onToggleChangeImage: PropTypes.func.isRequired,
  isAIGenerated: PropTypes.string,
  handleAssetInputChange: PropTypes.func.isRequired,
}

StandardAsset.defaultProps = {
  isAIGenerated: undefined,
}
