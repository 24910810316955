import React, { useMemo, useCallback, useRef, useState, useEffect, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import {
  enhancePresentationSelector,
  enhanceLoadingSelector,
} from '_modules/presentations/selectors'
import { enhanceAssetLoadingSelector, enhanceAssetSelector } from '_modules/assets/selectors'
import EnhanceDropdown from '_components/enhance/enhance-dropdown'
import WandIcon from '_assets/icons/wand-icon.svg'
import CloseIcon from '_assets/icons/close-masonry.svg'
import Svg from '_components/svg'
import useWindowSize from '_hooks/use-window-size'

import styles from './styles.css'

const TEXT_LENGTH_OPTIONS = [
  {
    labelEmoji: '➕',
    labelText: 'Longer',
    arialLabel: 'More emoji',
    value: 'more',
    id: 'length-more',
  },
  {
    labelEmoji: '➖',
    labelText: 'Shorter',
    arialLabel: 'Minus emoji',
    value: 'less',
    id: 'length-less',
  },
  {
    labelEmoji: '✨',
    arialLabel: 'Star emoji',
    labelText: 'Same Length',
    value: 'same',
    id: 'length-same',
  },
]

const TEXT_TONE_OPTIONS = [
  {
    labelEmoji: '👍',
    labelText: 'Casual',
    arialLabel: 'Like emoji',
    value: 'casual',
    id: 'tone-casual',
  },
  {
    labelEmoji: '💼',
    labelText: 'Professional',
    arialLabel: 'Briefcase emoji',
    value: 'professional',
    id: 'tone-professional',
  },
  {
    labelEmoji: '😄',
    labelText: 'Happier',
    arialLabel: 'Happy emoji',
    value: 'happier',
    id: 'tone-happier',
  },
  {
    labelEmoji: '✨',
    arialLabel: 'Star emoji',
    labelText: 'Same Tone',
    value: 'same',
    id: 'tone-same',
  },
]

const observerConfig = { childList: true, subtree: true }

const EnhanceModal = ({
  onClose,
  handleEnhance,
  onAcceptSuggestion,
  assetId,
  isLoadingSaveSuggestion,
  setting,
  handleChangeSetting,
  buttonRef,
  isOpen,
}) => {
  const presentationSuggestion = useSelector(enhancePresentationSelector)
  const assetSuggestion = useSelector(enhanceAssetSelector)
  const isLoadingPresentation = useSelector(enhanceLoadingSelector)
  const isLoadingAsset = useSelector(enhanceAssetLoadingSelector)
  const containerRef = useRef(null)
  const [heightContainer, setHeightContainer] = useState()
  const [styleState, setStyle] = useState()
  const { isMobile, width } = useWindowSize()

  const suggestion = useMemo(() => {
    if (assetId) {
      return assetSuggestion
    }

    return presentationSuggestion
  }, [assetId, assetSuggestion, presentationSuggestion])

  const isLoading = useMemo(() => isLoadingPresentation || isLoadingAsset, [
    isLoadingAsset,
    isLoadingPresentation,
  ])

  const handleRetry = useCallback(() => {
    handleEnhance(true)()
  }, [handleEnhance])

  const onEnhance = useCallback(() => {
    handleEnhance(false)()
  }, [handleEnhance])

  const handleAcceptedSuggestion = useCallback(() => {
    onAcceptSuggestion()
  }, [onAcceptSuggestion])

  const renderSuggestion = useMemo(
    () => (
      <div className={styles['suggestion-wrapper']}>
        <h2 className={styles.title}>Suggestion:</h2>
        <div className={styles['suggestion-container']}>
          <p className={styles.suggestion}>{suggestion.get('enhancedText')}</p>
        </div>
        <p className={styles.disclaimer}>
          Disclaimer: always double-check AI suggestions before accepting.
        </p>

        <Button
          className={styles['button-accept']}
          size={BUTTON_SIZE.MEDIUM}
          disabled={isLoading || isLoadingSaveSuggestion}
          onClick={handleAcceptedSuggestion}
          isLoading={isLoadingSaveSuggestion}
        >
          Use Suggestion
        </Button>
      </div>
    ),
    [handleAcceptedSuggestion, isLoading, isLoadingSaveSuggestion, suggestion]
  )

  const onHeightChange = useCallback(() => {
    if (containerRef && containerRef.current) {
      const newHeight = containerRef.current.getBoundingClientRect().height
      if (newHeight && newHeight !== heightContainer) {
        setHeightContainer(newHeight)
      }
    }
  }, [heightContainer])

  useEffect(() => {
    const containerElement = containerRef.current

    if (containerElement) {
      const observer = new MutationObserver(() => {
        onHeightChange()
      })
      observer.observe(containerElement, observerConfig)

      onHeightChange()

      return () => {
        observer.disconnect()
      }
    }

    return () => {}
  }, [containerRef, onHeightChange])

  const getStyle = useCallback(() => {
    const MARGIN_TOP = isMobile ? 60 : 16
    const MARGIN_RIGHT = isMobile ? 10 : 60
    const WIDTH_MODAL = isMobile ? 360 : 480
    const HEADER_HEIGHT = 64

    if (heightContainer) {
      const buttonRect = buttonRef.current.getBoundingClientRect()
      const windowHeight = window.innerHeight

      const spaceAboveButton = buttonRect.top - HEADER_HEIGHT
      const spaceBelowButton = windowHeight - buttonRect.bottom - HEADER_HEIGHT

      if (spaceAboveButton > heightContainer) {
        return {
          top: `-${MARGIN_TOP + heightContainer}px`,
          left: `${window.innerWidth - (buttonRect.left + WIDTH_MODAL + MARGIN_RIGHT)}px`,
          opacity: '1',
        }
      }

      if (spaceBelowButton < heightContainer) {
        const scrollPosition = heightContainer - spaceBelowButton + window.scrollY
        window.scrollTo(0, scrollPosition)
      }

      return {
        top: `${buttonRect.height + MARGIN_TOP}px`,
        left: `${window.innerWidth - (buttonRect.left + WIDTH_MODAL + MARGIN_RIGHT)}px`,
        opacity: '1',
      }
    }

    return {}
  }, [buttonRef, heightContainer, isMobile])

  useLayoutEffect(() => {
    const style = getStyle()
    setStyle(style)
  }, [getStyle, width])

  return (
    <div
      className={classnames(styles.container, { [styles.open]: isOpen })}
      style={styleState}
      ref={containerRef}
    >
      <button
        type="button"
        aria-label="close modal"
        onClick={onClose}
        className={styles['close-button']}
      >
        <Svg icon={CloseIcon} className={styles.icon} />
      </button>
      <div className={styles.content}>
        {!!suggestion.size && renderSuggestion}
        <div className={styles.setting}>
          <h2 className={styles['setting-title']}>
            {!suggestion.size ? 'Enhance with AI' : 'Need a new one? Try again:'}
          </h2>
          <div className={styles['wrapper-settings']}>
            <EnhanceDropdown
              handleChange={handleChangeSetting}
              value={setting.length}
              options={TEXT_LENGTH_OPTIONS}
              name="length"
            />
            <EnhanceDropdown
              handleChange={handleChangeSetting}
              value={setting.tone}
              options={TEXT_TONE_OPTIONS}
              name="tone"
            />
          </div>
          <Button
            className={styles['button-generate']}
            theme={BUTTON_THEME.PRIMARY}
            size={BUTTON_SIZE.MEDIUM}
            disabled={isLoading}
            startIcon={WandIcon}
            isLoading={isLoading}
            onClick={!suggestion.size ? onEnhance : handleRetry}
          >
            {!suggestion.size ? 'Generate' : 'Generate new'}
          </Button>
        </div>
      </div>
    </div>
  )
}
export default EnhanceModal

EnhanceModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  handleEnhance: PropTypes.func.isRequired,
  onAcceptSuggestion: PropTypes.func.isRequired,
  assetId: PropTypes.number,
  isLoadingSaveSuggestion: PropTypes.bool,
  setting: PropTypes.shape({
    length: PropTypes.string.isRequired,
    tone: PropTypes.string.isRequired,
  }).isRequired,
  handleChangeSetting: PropTypes.func.isRequired,
  buttonRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.shape({}) }),
  ]).isRequired,
  isOpen: PropTypes.bool,
}

EnhanceModal.defaultProps = {
  assetId: undefined,
  isLoadingSaveSuggestion: false,
  isOpen: false,
}
