import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import VideoPlayer from '_components/video-player'
import EnvelopeIcon from '_assets/icons/envelope.svg'
import { Presentation } from '_models'
import { PRESENTATION_EMPTY_TITLE } from '_constants/presentation'
import Button, { BUTTON_THEME } from '_components/button-round'
import TextEditor from '_components/text-editor'
import { convertTag, HIERARCHY_TYPES_ELEMENTS } from '_components/text-editor/utils'
import UserEmptyPicture from '_assets/icons/ic-placeholder-user.svg'
import Svg from '_components/svg'
import { NO_PICTURE } from '_utils/constants'
import { GREY_88 } from '_constants/colors'
import { checkTitle } from '_utils/helpers'
import { userSelector } from '_modules/user/selectors'

import styles from './styles.css'

const CoverSection = ({ presentation, isClientView, className }) => {
  const user = useSelector(userSelector)
  const backgroundVideoUrl = presentation.get('backgroundVideoUrl')

  const headerStyle = useMemo(() => {
    const background = presentation.get('backgroundCropped') || presentation.get('background')
    const backgroundOpacity = presentation.get('backgroundOpacity')
    const whiteOpacity = backgroundOpacity - 0.4 < 0 ? 0 : backgroundOpacity - 0.4

    if (background) {
      return {
        backgroundImage: `linear-gradient(rgba(0, 0, 0, ${Number(
          backgroundOpacity
        )}), rgba(0, 0, 0, ${whiteOpacity})), url(${background})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }
    }
    return {
      background: `linear-gradient(rgba(0, 0, 0, ${Number(
        backgroundOpacity
      )}), rgba(0, 0, 0, ${whiteOpacity}))`,
      backgroundColor: presentation.get('backgroundColor') || GREY_88,
    }
  }, [presentation])

  const generateTitle = useCallback(() => {
    if (checkTitle(presentation.get('title'))) {
      return convertTag(presentation.get('title'), HIERARCHY_TYPES_ELEMENTS.TITLE)
    }
    return (
      presentation.get('title') ||
      `<h1 style="text-align: ${presentation.get(
        'picturePosition'
      )};">${PRESENTATION_EMPTY_TITLE}</h1>`
    )
  }, [presentation])

  const getCreatedByValue = useMemo(() => {
    const clientViewUserName =
      presentation.get('userName') ||
      `${presentation.getIn(['user', 'firstName'])} ${presentation.getIn(['user', 'lastName'])}`

    const userName =
      presentation.get('userName') || (user && `${user.get('first_name')} ${user.get('last_name')}`)

    const text = isClientView ? clientViewUserName : userName

    return text
  }, [presentation, user, isClientView])

  const getUserPicturePresentation = useMemo(() => {
    const image = isClientView
      ? presentation.get('picture') || presentation.getIn(['user', 'picture'])
      : presentation.get('picture') || user.get('picture')

    return image ? (
      <img className={styles.avatar} alt="Author" src={image} />
    ) : (
      <Svg className={styles.avatar} icon={UserEmptyPicture} />
    )
  }, [presentation, isClientView, user])

  const getEmail = useMemo(() => {
    if (isClientView) {
      return presentation.getIn(['user', 'email'])
    }

    return user && user.get('email')
  }, [presentation, user, isClientView])

  return (
    <div className={classnames(styles['cover-wrapper'], className)}>
      {backgroundVideoUrl ? (
        <VideoPlayer backgroundVideoUrl={backgroundVideoUrl} />
      ) : (
        <div className={classnames(styles['cover-background'])} style={headerStyle} />
      )}
      <div className={classnames(styles.content, styles[presentation.get('picturePosition')])}>
        {!presentation.get('titleIsHidden') ? (
          <div
            className={styles.title}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: generateTitle(),
            }}
          />
        ) : (
          <h1 className={styles['hidden-title']}>
            {presentation.get('cleanTitle') || PRESENTATION_EMPTY_TITLE}
          </h1>
        )}
        {presentation.get('picturePosition') !== NO_PICTURE && (
          <React.Fragment>
            <div className={styles.author}>
              {getUserPicturePresentation}

              {!presentation.get('userNameIsHidden') && (
                <div className={styles['author-name']}>
                  <div
                    className={styles['created-by']}
                    style={{
                      color: presentation.get('createdByTextColor') || '#fff',
                    }}
                  >
                    Created by
                  </div>
                  <TextEditor
                    readOnly
                    toolbarHidden
                    inputClassName={styles['user-name']}
                    initialState={getCreatedByValue}
                    textAlignment={presentation.get('picturePosition')}
                  />
                </div>
              )}
            </div>

            <Button
              className={styles.contact}
              startIcon={EnvelopeIcon}
              theme={BUTTON_THEME.WHITE_GHOST}
              href={`mailto:${getEmail}`}
            >
              Email me
            </Button>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

CoverSection.propTypes = {
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  className: PropTypes.string,
  isClientView: PropTypes.bool.isRequired,
}

CoverSection.defaultProps = {
  className: null,
}

export default React.memo(CoverSection)
