import ScreenshotIcon from '_assets/icons/screenshot.svg'
import FileIcon from '_assets/icons/ic_file.svg'
import LinkIcon from '_assets/icons/link.svg'
import { ASSET_TYPE } from '_constants/presentation'
import { imageExtensions } from '_utils/files'
import { OPACITY_DEFAULT_VALUE } from '_components/opacity-changer'

const getFileExtension = filename => {
  const tokens = filename.split('.')
  const extension = tokens[tokens.length - 1]

  return extension.toLocaleLowerCase()
}

export const getAssetViewText = ({ filename, prefix = 'View', type }) => {
  switch (type) {
    case ASSET_TYPE.FILE:
      return filename
        ? `${prefix} ${getFileExtension(filename).toLocaleUpperCase()}`
        : `${prefix} file`
    case 'map':
      return `${prefix} map`
    case ASSET_TYPE.VIDEO:
      return `${prefix} video`
    case ASSET_TYPE.LINK:
      return `${prefix} link`
    case ASSET_TYPE.CONTACT:
      return `${prefix} contact`
    default:
      return `${prefix} file`
  }
}

export const getAssetType = ({ filename, type }) => {
  if (!type) return 'without-type'

  switch (type) {
    case ASSET_TYPE.FILE:
      return imageExtensions.some(extension => {
        const currentExtension = filename && filename.split('.')[filename.split('.').length - 1]

        return extension === currentExtension
      })
        ? 'Image'
        : 'File'
    case ASSET_TYPE.VIDEO:
      return 'video'
    case ASSET_TYPE.LINK:
      return 'link'
    case ASSET_TYPE.CONTACT:
      return 'contact'
    case ASSET_TYPE.IMAGE:
      return 'image'
    default:
      return `file`
  }
}

export const getAssetTypeIcon = type => {
  switch (type) {
    case ASSET_TYPE.VIDEO:
      return ScreenshotIcon
    case ASSET_TYPE.LINK:
      return LinkIcon
    default:
      return FileIcon
  }
}

export const getAssetFooterInfo = asset => {
  const assetType = getAssetType({
    filename: asset.get('filename'),
    type: asset.get('type'),
  })

  const isImage = imageExtensions.some(extension => extension === assetType)

  if (isImage) {
    return '1 image'
  }

  if (asset.get('website')) {
    return asset.get('website')
  }

  if (asset.get('numberOfPages')) {
    return `${asset.get('numberOfPages')} ${asset.get('numberOfPages') > 1 ? 'pages' : 'page'}`
  }

  if (asset.get('type') === ASSET_TYPE.CONTACT) {
    return 'Info'
  }

  return ''
}

export const getAssetThumbnail = asset => {
  const opacity = asset.get('backgroundOpacity') || OPACITY_DEFAULT_VALUE

  const whiteOpacity = opacity - 0.4 < 0 ? 0 : opacity - 0.4

  return {
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0,${opacity}) 0%, rgba(0, 0, 0,${whiteOpacity}) 134.8%), url(${asset.get(
      'thumbnailCropped'
    ) || asset.get('thumbnail')})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
  }
}
