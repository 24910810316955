import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PropTypes from 'prop-types'

import ReorderAssetIcon from '_assets/icons/reorder.svg'
import Svg from '_components/svg'

import styles from './styles.css'

const DraggableWrapper = ({ draggableId, index, children }) => (
  <Draggable key={`asset-${draggableId}`} draggableId={String(draggableId)} index={index}>
    {provided => (
      <div
        {...provided.draggableProps}
        ref={provided.innerRef}
        className={styles['draggable-wrapper']}
      >
        <div className={styles['drag-container']} {...provided.dragHandleProps}>
          <Svg icon={ReorderAssetIcon} className={styles['drag-icon']} />
        </div>
        <div className={styles.container}>{children}</div>
      </div>
    )}
  </Draggable>
)

export default DraggableWrapper

DraggableWrapper.propTypes = {
  index: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  draggableId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.node.isRequired,
}
