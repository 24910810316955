import React, { Fragment, useMemo, useCallback, useEffect, useRef } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'

import DismissAsset from '_assets/icons/remove_asset_button.svg'
import DownloadIcon from '_assets/icons/ic_download_asset.svg'
import FileIcon from '_assets/icons/ic_file.svg'
import LinkIcon from '_assets/icons/link.svg'
import TabNavigation, {
  TAB_NAVIGATION_THEME,
  TAB_NAVIGATION_ELEMENTS_POSITION,
} from '_components/tab-navigation'
import FileUploadContainer from '_components/file-upload-container'
import { BROWSE_FILES_THEME } from '_components/file-upload-container/browse-files'
import Button, { BUTTON_THEME } from '_components/button-round'
import Svg from '_components/svg'
import { Asset } from '_models'
import useToggle from '_hooks/use-modal'
import { getPlainText } from '_components/text-editor/utils'

import AssetProgressBar from '../asset-progress-bar'
import { UPDATE_STATE, TAB_NAVIGATION } from '../reducer'

import styles from './styles.css'

const WIDTH_WITHOUT_TAB_NAVIGATION = 1
const AssetInsertFileContainer = ({
  isChangeFile,
  className,
  asset,
  onDismiss,
  isAIGenerated,
  onSelectFile,
  state,
  localDispatch,
}) => {
  const [isChangeImage, onToggleIsChangeImage] = useToggle()

  const fileWrapperRef = useRef(null)

  const { width } = useWindowSize()

  const renderAssetThumbnail = useMemo(
    () => ({
      backgroundImage: `url(${asset.get('thumbnailCropped') || asset.get('thumbnail')})`,
      backgroundSize: 'cover',
      borderBottomLeftRadius: 8,
    }),
    [asset]
  )

  const tabNavigationStyle = useMemo(
    () => ({ width: state.fileWrapperWidth - WIDTH_WITHOUT_TAB_NAVIGATION }),
    [state.fileWrapperWidth]
  )

  const tabs = useMemo(
    () => [
      {
        label: 'File',
        startIcon: FileIcon,
        ordering: TAB_NAVIGATION.FILE,
      },
      {
        label: 'Link',
        startIcon: LinkIcon,
        ordering: TAB_NAVIGATION.LINK,
      },
    ],
    []
  )

  const onChangeTab = useCallback(
    ordering => localDispatch({ type: UPDATE_STATE, payload: { activeTab: ordering } }),
    [localDispatch]
  )

  const onDownloadFile = useCallback(() => {
    if (asset && asset.get('file')) {
      const downloadUrl = `/downloadFile/${asset
        .get('file')
        .split('/presentation-asset/')
        .pop()}${
        asset.get('fileName')
          ? `?fileName=${decodeURIComponent(encodeURIComponent(asset.get('filename'))).replace(
              /[^a-zA-Z0-9\s.-_]/g,
              '-'
            )}`
          : ''
      }`

      return downloadUrl
    }
    return ''
  }, [asset])

  const onChangeLink = useCallback(
    event => localDispatch({ type: UPDATE_STATE, payload: { link: event.target.value } }),
    [localDispatch]
  )

  const handleOnSelect = useCallback(
    file => {
      if (isChangeFile) {
        localDispatch({ type: UPDATE_STATE, payload: { file } })
        onToggleIsChangeImage()
        return
      }

      onSelectFile(file)
    },
    [isChangeFile, onSelectFile, onToggleIsChangeImage, localDispatch]
  )

  const onReplace = useCallback(() => {
    onSelectFile(state.file)
    onDismiss(true)
  }, [onSelectFile, state.file, onDismiss])

  const onKeepCurrent = useCallback(() => {
    onSelectFile(state.file, true)
    onDismiss(true)
  }, [onSelectFile, state.file, onDismiss])

  const renderContent = useMemo(() => {
    if (state.activeTab === TAB_NAVIGATION.LINK && !isChangeImage) {
      return (
        <div className={styles['link-file']}>
          <p className={styles['link-file-title']}>Enter URL</p>
          <input
            placeholder="https://www.address.com"
            className={styles.input}
            value={state.link}
            onChange={onChangeLink}
          />
          <Button
            className={styles['insert-button']}
            theme={BUTTON_THEME.DARK_BLUE}
            onClick={handleOnSelect}
          >
            Insert
          </Button>
        </div>
      )
    }

    if (isChangeImage) {
      return (
        <div style={renderAssetThumbnail}>
          <div className={styles['replace-image-wrapper']}>
            <p className={styles['replace-title']}>Replace cover image?</p>
            <Button
              className={styles['cancel-button']}
              theme={BUTTON_THEME.SECONDARY}
              onClick={onKeepCurrent}
            >
              Keep current
            </Button>
            <Button
              className={styles['replace-button']}
              theme={BUTTON_THEME.DARK_BLUE}
              onClick={onReplace}
            >
              Replace
            </Button>
          </div>
        </div>
      )
    }

    if (asset.get('unsplashPercentage')) {
      return (
        <AssetProgressBar
          assetId={asset.get('id')}
          className={styles['asset-file']}
          progressValue={asset.get('unsplashPercentage')}
          hideError
        />
      )
    }

    return (
      <div className={styles['upload-file']}>
        {isChangeFile && asset.get('type') === TAB_NAVIGATION.FILE && (
          <div className={styles['download-file']}>
            <p className={styles['download-text']}>{asset.get('filename')}</p>
            <Button
              className={styles['download-button']}
              theme={BUTTON_THEME.DARK_BLUE}
              startIcon={DownloadIcon}
              href={onDownloadFile()}
            >
              Download Item
            </Button>
          </div>
        )}
        <FileUploadContainer
          hasUnsplashOption
          onlyBrowseFiles={isChangeFile}
          className={classnames(styles['browse-files'], {
            [styles['change-file']]: isChangeFile,
          })}
          browseFilesDescription="Upload your files from:"
          browseFilesTheme={BROWSE_FILES_THEME.LIGHT}
          onSelectFiles={handleOnSelect}
          assetId={asset.get('id')}
          assetTitle={getPlainText(asset.get('title'))}
          isAIGenerated={isAIGenerated}
        />
      </div>
    )
  }, [
    onDownloadFile,
    asset,
    handleOnSelect,
    isAIGenerated,
    isChangeFile,
    isChangeImage,
    onChangeLink,
    onKeepCurrent,
    onReplace,
    renderAssetThumbnail,
    state.activeTab,
    state.link,
  ])

  useEffect(() => {
    if (fileWrapperRef) {
      localDispatch({
        type: UPDATE_STATE,
        payload: { fileWrapperWidth: fileWrapperRef.current.clientWidth },
      })
    }
  }, [fileWrapperRef, width, localDispatch])

  return (
    <div
      className={classnames(className, styles['file-wrapper'], {
        [styles['unsplash-loading']]: asset.get('unsplashPercentage'),
      })}
      ref={fileWrapperRef}
    >
      {!asset.get('unsplashPercentage') && (
        <TabNavigation
          activeTab={state.activeTab}
          className={styles['tab-navigation']}
          elementsPosition={TAB_NAVIGATION_ELEMENTS_POSITION.CENTER}
          style={tabNavigationStyle}
          tabs={tabs}
          theme={TAB_NAVIGATION_THEME.LIGHT}
          onClick={onChangeTab}
        />
      )}
      {isChangeFile && (
        <Fragment>
          <button className={styles['dismiss-button']} onClick={onDismiss} type="button">
            <Svg icon={DismissAsset} className={styles['dismiss-button-image']} />
          </button>
        </Fragment>
      )}
      {renderContent}
    </div>
  )
}
AssetInsertFileContainer.propTypes = {
  isChangeFile: PropTypes.bool.isRequired,
  className: PropTypes.string,
  asset: PropTypes.instanceOf(Asset).isRequired,
  onDismiss: PropTypes.func.isRequired,
  isAIGenerated: PropTypes.string,
  onSelectFile: PropTypes.func.isRequired,
  state: PropTypes.shape({
    activeTab: PropTypes.string,
    fileWrapperWidth: PropTypes.number,
    link: PropTypes.string,
    file: PropTypes.instanceOf(File),
    replace: PropTypes.bool,
  }).isRequired,
  localDispatch: PropTypes.func.isRequired,
}

AssetInsertFileContainer.defaultProps = {
  className: null,
  isAIGenerated: undefined,
}

export default React.memo(AssetInsertFileContainer)
