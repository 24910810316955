import React, { Fragment, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Map } from 'immutable'
import { useDispatch } from 'react-redux'

import PlayIcon from '_assets/icons/play.svg'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import {
  getAssetThumbnail,
  getAssetViewText,
  getAssetFooterInfo,
  getAssetTypeIcon,
  getAssetType,
} from '_utils/asset'
import Svg from '_components/svg'
import { Presentation } from '_models'
import { ASSET_TYPE, getConvertedFileAsset } from '_constants/presentation'
import TextEditor from '_components/text-editor'
import withAssetVisualization from '_hocs/with-asset-visualization'
import { getAssetConvertedFile } from '_modules/assets/actions'

import styles from './styles.css'

const StandardStyleSection = ({ title, asset, presentation, toggleVisualization }) => {
  const dispatch = useDispatch()

  const onToggleVisualization = useCallback(() => {
    if (!asset.get('convertedFiles').size && getConvertedFileAsset.includes(asset.get('type'))) {
      dispatch(
        getAssetConvertedFile({
          assetId: asset.get('id'),
          presentationId: presentation.get('id'),
        })
      )
    }

    toggleVisualization(asset.get('position'))
  }, [asset, presentation, dispatch, toggleVisualization])

  if (asset.get('type') === ASSET_TYPE.IMAGE) {
    return (
      <div
        key={`asset-${asset.get('id')}-of-presentation-${presentation.get('id')}`}
        className={classnames(
          styles['standard-card-wrapper'],
          styles[
            getAssetType({
              filename: asset.get('filename'),
              type: asset.get('type'),
            })
          ]
        )}
      >
        <div className={styles.thumbnail} style={getAssetThumbnail(asset)}>
          <span className={styles['thumbnail-hover']}>
            <Svg icon={PlayIcon} className={styles['play-icon']} />
            <Button
              className={styles['view-button']}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              onClick={onToggleVisualization}
              size={BUTTON_SIZE.SMALL}
            >
              View
            </Button>
          </span>
        </div>
      </div>
    )
  }

  return (
    <div
      key={`asset-${asset.get('id')}-of-presentation-${presentation.get('id')}`}
      className={classnames(
        styles['standard-card-wrapper'],
        styles[
          getAssetType({
            filename: asset.get('filename'),
            type: asset.get('type'),
          })
        ]
      )}
    >
      {asset.get('type') && (
        <Fragment>
          <div className={styles.thumbnail} style={getAssetThumbnail(asset)}>
            <span className={styles['thumbnail-hover']}>
              <Svg icon={PlayIcon} className={styles['play-icon']} />
              <Button
                className={styles['view-button']}
                theme={BUTTON_THEME.TRANSPARENT_FILL}
                onClick={onToggleVisualization}
                size={BUTTON_SIZE.SMALL}
              >
                View
              </Button>
            </span>
          </div>
        </Fragment>
      )}
      <div className={styles['title-wrapper']}>
        {asset.get('type') && (
          <div className={styles.type}>
            <Svg icon={getAssetTypeIcon(asset.get('type'))} className={styles['type-icon']} />
          </div>
        )}

        <div
          className={styles.title}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
      </div>

      <TextEditor
        readOnly
        toolbarHidden
        wrapperClassName={styles.description}
        inputClassName={styles['description-input']}
        initialState={asset.get('description')}
      />
      {asset.get('type') && (
        <div className={styles['footer-content']}>
          <Button
            theme={BUTTON_THEME.SECONDARY}
            onClick={onToggleVisualization}
            size={BUTTON_SIZE.SMALL}
          >
            {getAssetViewText({
              type: asset.get('type'),
              prefix: asset.get('type') === ASSET_TYPE.VIDEO ? 'Watch' : 'View',
            })}
          </Button>
          <span className={styles['footer-info']} title={asset.get('website')}>
            {getAssetFooterInfo(asset)}
          </span>
        </div>
      )}
    </div>
  )
}

StandardStyleSection.propTypes = {
  asset: PropTypes.instanceOf(Map).isRequired,
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  toggleVisualization: PropTypes.func.isRequired,
  title: PropTypes.string,
}

StandardStyleSection.defaultProps = {
  title: '',
}

export default withAssetVisualization(React.memo(StandardStyleSection))
