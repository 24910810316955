import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import FileIcon from '_assets/icons/ic_file.svg'
import LeftArrow from '_assets/icons/left_arrow.svg'
import { ASSET_TYPE } from '_constants/presentation'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import Svg from '_components/svg'
import LinkIcon from '_assets/icons/link.svg'
import VideoLinkIcon from '_assets/icons/ri_video-fill.svg'
import { editPresentationAsset } from '_modules/assets/actions'

import styles from './styles.css'

const AssetTitle = ({ type, assetId, presentationId }) => {
  const dispatch = useDispatch()

  const handleClick = useCallback(() => {
    dispatch(
      editPresentationAsset({
        id: assetId,
        presentationId,
        payload: { type: null },
      })
    )
  }, [assetId, dispatch, presentationId])

  const getIcon = assetType => {
    switch (assetType) {
      case ASSET_TYPE.FILE:
        return FileIcon
      case ASSET_TYPE.LINK:
        return LinkIcon
      case ASSET_TYPE.VIDEO_URL:
        return VideoLinkIcon
      default:
        return FileIcon
    }
  }

  return (
    <div className={styles.container}>
      <Button
        type="button"
        size={BUTTON_SIZE.SMALL}
        theme={BUTTON_THEME.LINK_BUTTON}
        onClick={handleClick}
        startIcon={LeftArrow}
        className={styles.backButton}
      >
        Back
      </Button>
      <div className={styles.content}>
        <div className={styles['icon-wrapper']}>
          <Svg icon={getIcon(type)} className={styles.icon} />
        </div>
        <p className={styles.title}>{type}</p>
      </div>
    </div>
  )
}

AssetTitle.propTypes = {
  type: PropTypes.oneOf(Object.values(ASSET_TYPE)),
  assetId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  presentationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

AssetTitle.defaultProps = {
  type: ASSET_TYPE.FILE,
}

export default AssetTitle
