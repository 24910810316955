export const PRESENTATION_EMPTY_TITLE = 'Add a Title'

export const ASSET_EMPTY_TITLE = 'Item Title'

export const ASSET_TYPE = {
  FILE: 'file',
  LINK: 'website',
  VIDEO: 'video',
  CONTACT: 'form',
  SEPARATOR: 'separator',
  SEPARATOR_HIDDEN: 'separator_hidden',
  TEXT: 'text',
  IMAGE: 'image',
  VIDEO_URL: 'video_url',
}

export const getConvertedFileAsset = [ASSET_TYPE.IMAGE, ASSET_TYPE.FILE]
