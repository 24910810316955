import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import Asset from '_models/asset'
import { Presentation } from '_models'
import { getAssetThumbnail, getAssetType } from '_utils/asset'
import { PRESENTATION_STYLE_BARCELONA } from '_constants/presentation-styles'
import withAssetVisualization from '_hocs/with-asset-visualization'
import { getAssetConvertedFile } from '_modules/assets/actions'

import styles from './styles.css'

const PreviewFullImageAsset = ({ asset, presentation, toggleVisualization }) => {
  const dispatch = useDispatch()

  const onToggleVisualization = useCallback(() => {
    if (!asset.get('convertedFiles').size) {
      dispatch(
        getAssetConvertedFile({
          assetId: asset.get('id'),
          presentationId: presentation.get('id'),
        })
      )
    }

    toggleVisualization(asset.get('position'))
  }, [asset, presentation, dispatch, toggleVisualization])

  if (presentation.get('style') === PRESENTATION_STYLE_BARCELONA) {
    return (
      <div
        key={`asset-${asset.get('id')}-of-presentation-${presentation.get('id')}`}
        className={classnames(
          styles['barcelona-card-wrapper'],
          styles[
            getAssetType({
              filename: asset.get('filename'),
              type: asset.get('type'),
            })
          ]
        )}
      >
        {asset.get('type') && (
          <button
            type="button"
            onClick={onToggleVisualization}
            className={styles.thumbnail}
            style={getAssetThumbnail(asset)}
          >
            <span className={styles.type}>
              {getAssetType({
                filename: asset.get('filename'),
                type: asset.get('type'),
              }).toUpperCase()}
            </span>
          </button>
        )}
      </div>
    )
  }

  return (
    <div
      key={`asset-${asset.get('id')}-of-presentation-${presentation.get('id')}`}
      className={classnames(
        styles['standard-card-wrapper'],
        styles[
          getAssetType({
            filename: asset.get('filename'),
            type: asset.get('type'),
          })
        ]
      )}
    >
      <button
        type="button"
        onClick={onToggleVisualization}
        className={styles.thumbnail}
        style={getAssetThumbnail(asset)}
      />
    </div>
  )
}

PreviewFullImageAsset.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(Asset)]).isRequired,
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  toggleVisualization: PropTypes.func.isRequired,
}

export default withAssetVisualization(React.memo(PreviewFullImageAsset))
