import { EditorState, ContentState, SelectionState, Modifier, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { Map } from 'immutable'

import { NO_PICTURE } from '_utils/constants'

export const HIERARCHY_TYPES_ELEMENTS = {
  TITLE: 'h1',
  SUBTITLE: 'h2',
  PARAGRAPH: 'p',
}

const HIERARCHY_TYPES = {
  [HIERARCHY_TYPES_ELEMENTS.TITLE]: 'header-one',
  [HIERARCHY_TYPES_ELEMENTS.SUBTITLE]: 'header-two',
  [HIERARCHY_TYPES_ELEMENTS.PARAGRAPH]: 'unstyled',
}

export const getInitialTextBold = () => {
  const initialContent = ContentState.createFromText(' ')

  const selectionState = SelectionState.createEmpty(initialContent.getFirstBlock().getKey()).merge({
    anchorOffset: 0,
    focusOffset: initialContent.getPlainText().length,
  })
  const contentWithItalic = Modifier.applyInlineStyle(initialContent, selectionState, 'BOLD')

  const initialEditorState = EditorState.createWithContent(contentWithItalic)

  return initialEditorState
}

export const convertTag = (htmlText, typeToConvert) => {
  const contentBlock = htmlToDraft(htmlText)
  const contentStateInitial = ContentState.createFromBlockArray(contentBlock.contentBlocks)
  const editorStateInitial = EditorState.createWithContent(contentStateInitial)
  const selectionState = editorStateInitial.getSelection()
  const editContentState = Modifier.setBlockType(
    contentStateInitial,
    selectionState,
    HIERARCHY_TYPES[typeToConvert]
  )

  return draftToHtml(
    convertToRaw(EditorState.createWithContent(editContentState).getCurrentContent())
  )
}

export const convertHierarchText = (state, type) => {
  const selectionState = state.getSelection()
  const editContentState = Modifier.setBlockType(
    state.getCurrentContent(),
    selectionState,
    HIERARCHY_TYPES[type]
  )

  return editContentState
}

export const convertInitialState = (initialValue, textAlignment) => {
  if (!initialValue && !textAlignment) {
    return EditorState.createEmpty()
  }
  const contentBlock = htmlToDraft(initialValue)
  const dataBlock = contentBlock.contentBlocks[0].getData()

  const contentStateInitial = ContentState.createFromBlockArray(contentBlock.contentBlocks)

  if (textAlignment && initialValue && dataBlock && !dataBlock.get('text-align')) {
    const editorStateInitial = EditorState.createWithContent(contentStateInitial)
    const editContentState = Modifier.setBlockData(
      contentStateInitial,
      editorStateInitial.getSelection(),
      Map([['text-align', textAlignment === NO_PICTURE ? 'center' : textAlignment]])
    )

    return EditorState.createWithContent(editContentState)
  }

  return EditorState.createWithContent(contentStateInitial)
}

export const getPlainText = htmlText => {
  if (htmlText) {
    return convertInitialState(htmlText)
      .getCurrentContent()
      .getPlainText()
  }
  return ''
}
