import { useState, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { generateFormDataToUploadOnS3 } from '_utils/helpers'
import { assetFileUploadConvert, setIsUploadingOnS3 } from '_modules/assets/actions'

const useSendFile = () => {
  const dispatch = useDispatch()
  const [error, setError] = useState(null)

  const sendFile = useCallback(
    (asset, presentationId, file, convertPayload) => {
      try {
        const fields = asset.get('fields')
        const URL = asset.get('url')
        const formData = generateFormDataToUploadOnS3({ fields, file })
        dispatch(setIsUploadingOnS3(asset.get('id')))

        fetch(URL, {
          method: 'POST',
          body: formData,
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Network response was not ok')
            }
            return response
          })
          .then(() => {
            dispatch(
              assetFileUploadConvert({
                presentationId,
                assetId: asset.get('id'),
                payload: {
                  ...convertPayload,
                  originalFilename: file.name,
                  key: fields.key,
                },
              })
            )
          })
      } catch (err) {
        setError(err)
      }
    },
    [dispatch]
  )

  return { error, sendFile }
}

export default useSendFile
