import React, { Fragment, useMemo, useCallback, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import { useDispatch } from 'react-redux'

import FileIcon from '_assets/icons/ic_file.svg'
import Button, { BUTTON_THEME } from '_components/button-round'
import Input from '_components/ui-kit/input'
import Svg from '_components/svg'
import WysiwygTextfield from '_components/wysiwyg-textfield'
import { ALERT_TYPE } from '_components/toast'
import { editPresentationAsset } from '_modules/assets/actions'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { sendContactMessage, SEND_CONTACT_MESSAGE } from '_modules/presentations/actions'
// import { getPresentationAssetByIdSelector } from '_modules/assets/selectors'
import validateEmail from '_utils/email-validation'
import useFetchCall from '_hooks/use-fetch-call'
import { getPlainText } from '_components/text-editor/utils'
import Asset from '_models/asset'

import AssetMoreActions from '../components/asset-more-actions'
import DraggableWrapper from '../components/draggable-wrapper'

import styles from './styles.css'

const INITIAL_STATE = { name: '', email: '', phone: '', message: '' }

const FIELDS_NAMES = {
  NAME: 'name',
  EMAIL: 'email',
  MESSAGE: 'message',
  PHONE: 'phone',
}
/* TODO: when the old views are removed, get the asset via selector to not generate prop drilling,
at the moment it is not possible because each view has a different module */

export const FormAsset = ({
  isPreview,
  isOldModule,
  toolbarHidden,
  /* assetId, */ asset,
  presentationId,
  index,
}) => {
  const [values, setValues] = useState(INITIAL_STATE)
  const [errors, setErrors] = useState({})

  const dispatch = useDispatch()

  // const asset = useSelector(state => getPresentationAssetByIdSelector(state, assetId))

  const fields = useMemo(
    () => [
      {
        label: 'Name (required)',
        name: FIELDS_NAMES.NAME,
        value: values.name,
      },
      {
        label: 'Email(required)',
        name: FIELDS_NAMES.EMAIL,
        value: values.email,
      },
      {
        label: 'Phone',
        name: FIELDS_NAMES.PHONE,
        value: values.phone,
      },
      {
        label: 'Message (required)',
        name: FIELDS_NAMES.MESSAGE,
        value: values.message,
        type: 'textarea',
      },
    ],
    [values]
  )

  const renderIcon = useMemo(
    () => (
      <span className={styles.type}>
        <Svg icon={FileIcon} className={styles['type-icon']} />
      </span>
    ),
    []
  )

  const handleTitleChange = useCallback(
    debounce(
      (name, value) =>
        dispatch(
          editPresentationAsset({
            id: asset.get('id'),
            presentationId,
            params: {},
            payload: { [name]: value },
          })
        ),
      300
    ),
    []
  )

  const onTitleChange = useCallback(({ name, value }) => handleTitleChange(name, value), [
    handleTitleChange,
  ])

  const validateForm = useCallback(currentValues => {
    const errorsMessage = {}
    const isValidEmail = !validateEmail(currentValues.email)
    if (!currentValues.name) {
      errorsMessage[FIELDS_NAMES.NAME] = 'Please type your name'
    }

    if (!currentValues.message) {
      errorsMessage[FIELDS_NAMES.MESSAGE] = 'Please type your message'
    }

    if (!isValidEmail) {
      errorsMessage[FIELDS_NAMES.EMAIL] = 'Please type a valid email'
    }

    setErrors(errorsMessage)
    return errorsMessage
  }, [])

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target

      const currentValues = { ...values, [name]: value }
      setValues(currentValues)

      if (Object.keys(errors).length > 0) {
        validateForm(currentValues)
      }
    },
    [errors, validateForm, values]
  )

  const onSubmit = useCallback(
    event => {
      event.preventDefault()

      const validationResult = validateForm(values)
      if (Object.keys(validationResult).length === 0) {
        dispatch(
          sendContactMessage(presentationId, {
            ...values,
          })
        )
      }
    },
    [dispatch, presentationId, validateForm, values]
  )

  const onSuccessSendMessage = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.SUCCESS,
        message: 'Message submitted successfully!',
      })
    )
    setValues(INITIAL_STATE)
  }, [dispatch])

  const onRejectSendMessage = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong. Please try again later.',
      })
    )
  }, [dispatch])

  const [isLoading] = useFetchCall(SEND_CONTACT_MESSAGE, onSuccessSendMessage, onRejectSendMessage)

  const renderContent = useMemo(
    () => (
      <Fragment>
        <form className={styles.content}>
          <span className={styles['title-container']}>
            {!isPreview && renderIcon}
            {(!isPreview || (isPreview && !!getPlainText(asset.get('title')))) && (
              <WysiwygTextfield
                toolbarHidden={toolbarHidden}
                readOnly={isPreview}
                className={styles.title}
                inputClassName={classnames(styles['title-input'], {
                  [styles['is-preview']]: isPreview,
                })}
                name="title"
                maxLength={78}
                placeholder="Write your title here..."
                value={asset.get('title')}
                onChange={onTitleChange}
                presentationId={presentationId}
                assetId={asset.get('id')}
              />
            )}
          </span>
          {fields.map(field => (
            <Fragment key={`input-${field.name}`}>
              <Input
                id={`input-${field.name}`}
                className={styles.input}
                textareaClassName={classnames(styles.textarea, {
                  [styles['is-preview']]: isPreview,
                })}
                label={field.label}
                type={field.type}
                value={field.value}
                disabled={!isPreview}
                name={field.name}
                onChange={onInputChange}
                error={errors[field.name]}
                {...(field.mask && { mask: field.mask })}
              />
            </Fragment>
          ))}
          <Button
            className={styles['submit-button']}
            type="submit"
            theme={BUTTON_THEME.SECONDARY}
            disabled={!isPreview || Object.keys(errors).length !== 0}
            isLoading={isLoading}
            onClick={onSubmit}
          >
            Submit
          </Button>
        </form>
      </Fragment>
    ),
    [
      isPreview,
      renderIcon,
      asset,
      toolbarHidden,
      onTitleChange,
      presentationId,
      fields,
      errors,
      isLoading,
      onSubmit,
      onInputChange,
    ]
  )

  if (isPreview) {
    return (
      <div
        className={classnames(styles['form-asset-wrapper'], { [styles['is-preview']]: isPreview })}
      >
        {renderContent}
      </div>
    )
  }

  return (
    <DraggableWrapper draggableId={String(asset.get('id'))} index={index}>
      <div className={styles['form-asset-wrapper']}>
        <h1 className={styles['asset-type']}>
          {renderIcon}
          Contact Form
        </h1>
        {renderContent}
        <AssetMoreActions
          isOldModule={isOldModule}
          asset={asset}
          className={styles['asset-actions']}
          presentationId={presentationId}
        />
      </div>
    </DraggableWrapper>
  )
}

FormAsset.propTypes = {
  // assetId: PropTypes.number.isRequired,
  asset: PropTypes.instanceOf(Asset).isRequired,
  presentationId: PropTypes.number.isRequired,
  index: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isPreview: PropTypes.bool,
  isOldModule: PropTypes.bool,
  toolbarHidden: PropTypes.bool,
}

FormAsset.defaultProps = {
  isPreview: false,
  isOldModule: false,
  toolbarHidden: false,
}
