import React, { useCallback, useState } from 'react'
import Dropzone from 'react-dropzone'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import DismissIcon from '_assets/icons/close-masonry.svg'
import PortraitIcon from '_assets/icons/portrait-icon.svg'
import UploadIcon from '_assets/icons/upload-icon.svg'
import Svg from '_components/svg'
import useWindowSize from '_hooks/use-window-size'
import withBrowserInformation from '_hocs/with-browser-information'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { ALERT_TYPE } from '_components/toast'
import { ALL_TYPES_EXTENSIONS, IMAGE_TYPES_EXTENSIONS } from '_utils/files'

import styles from './styles.css'
import BrowseFiles, { BROWSE_FILES_THEME } from './browse-files'

const FileUploadContainer = ({
  hasUnsplashOption,
  isOnlyImagesAllowed,
  browseFilesDescription,
  browseFilesTheme,
  className,
  onSelectFiles,
  onlyBrowseFiles,
  onDismiss,
  isTabletDevice,
  assetId,
  assetTitle,
  isAIGenerated,
  dropTitleText,
  onDismissDropzone,
}) => {
  const [isDragEnter, setDragEnter] = useState(false)
  const [isUploadOptions, setUploadOptions] = useState(false)
  const dispatch = useDispatch()

  const { isMobile, isTablet } = useWindowSize()

  const onDrop = useCallback(
    files => {
      if (!files.length) {
        return
      }

      const file = files[0]

      onSelectFiles(file)
      setDragEnter(false)
    },
    [onSelectFiles]
  )

  const onDragLeave = useCallback(() => {
    setDragEnter(false)
  }, [])
  const onDragEnter = useCallback(() => {
    setDragEnter(true)
  }, [])

  const onClickToUpload = useCallback(() => {
    setUploadOptions(true)
  }, [])

  const onHandleDismiss = useCallback(() => {
    if (onlyBrowseFiles) {
      onDismiss()
    }

    setUploadOptions(false)
  }, [onDismiss, onlyBrowseFiles])

  const handleDropRejected = useCallback(
    file => {
      if (file.some(item => item.type.includes('video'))) {
        const message =
          'Please host your video on a platform like YouTube or Vimeo and provide an accessible link for uploading!'

        dispatch(
          openToasterAlert({
            type: ALERT_TYPE.ERROR,
            message,
          })
        )
      }

      if (isDragEnter) {
        onDragLeave()
      }
    },
    [dispatch, isDragEnter, onDragLeave]
  )
  const handlePaste = useCallback(
    event => {
      if (event.clipboardData.files.length) {
        const fileObject = event.clipboardData.files[0]
        onSelectFiles(fileObject)
        setDragEnter(false)
        return
      }

      dispatch(
        openToasterAlert({
          type: ALERT_TYPE.ERROR,
          message:
            'No image data was found in your clipboard. Copy an image first or take a screenshot.',
        })
      )
    },
    [dispatch, onSelectFiles]
  )

  return isUploadOptions || onlyBrowseFiles || isMobile || (isTablet && !!isTabletDevice) ? (
    <div className={classnames(className, styles.content)} onPaste={handlePaste}>
      {onDismiss && (
        <button
          className={styles['dismiss-button']}
          onClick={onHandleDismiss}
          type="button"
          aria-label="Dismiss browse files"
        >
          <Svg icon={DismissIcon} className={styles['dismiss-button-image']} />
        </button>
      )}
      <BrowseFiles
        hasUnsplashOption={hasUnsplashOption}
        isOnlyImagesAllowed={isOnlyImagesAllowed}
        description={browseFilesDescription}
        theme={browseFilesTheme}
        onSelectFile={onSelectFiles}
        assetId={assetId}
        assetTitle={assetTitle}
        isAIGenerated={isAIGenerated}
      />
    </div>
  ) : (
    <Dropzone
      disableClick
      onDrop={onDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDropRejected={handleDropRejected}
      accept={isOnlyImagesAllowed ? IMAGE_TYPES_EXTENSIONS : ALL_TYPES_EXTENSIONS}
      className={classnames(className, styles['drop-zone'])}
      multiple={false}
    >
      {isDragEnter ? (
        <div className={classnames(className, styles['drop-content'])}>
          <Svg icon={UploadIcon} className={styles['drop-icon']} />
          <p className={styles['drop-text']}>You can drop your file here!</p>
        </div>
      ) : (
        <div className={classnames(className, styles.content)} onPaste={handlePaste}>
          {onDismissDropzone && (
            <button
              className={styles['dismiss-button']}
              onClick={onDismissDropzone}
              type="button"
              aria-label="Dismiss browse files"
            >
              <Svg icon={DismissIcon} className={styles['dismiss-button-image']} />
            </button>
          )}
          <svg aria-hidden="true" className={styles.icon}>
            <use xlinkHref={PortraitIcon} />
          </svg>
          <p className={styles.title}>{dropTitleText}</p>
          <p className={styles.description}>
            or
            <button className={styles.underline} onClick={onClickToUpload} type="button">
              click here to upload
            </button>
          </p>
        </div>
      )}
    </Dropzone>
  )
}

FileUploadContainer.propTypes = {
  hasUnsplashOption: PropTypes.bool,
  onlyBrowseFiles: PropTypes.bool,
  isOnlyImagesAllowed: PropTypes.bool,
  browseFilesDescription: PropTypes.string,
  browseFilesTheme: PropTypes.oneOf(Object.values(BROWSE_FILES_THEME)),
  className: PropTypes.string,
  onSelectFiles: PropTypes.func.isRequired,
  onDismiss: PropTypes.func,
  isTabletDevice: PropTypes.bool.isRequired,
  assetId: PropTypes.number,
  assetTitle: PropTypes.string,
  isAIGenerated: PropTypes.string,
  dropTitleText: PropTypes.string,
  onDismissDropzone: PropTypes.func,
}

FileUploadContainer.defaultProps = {
  hasUnsplashOption: false,
  onlyBrowseFiles: false,
  isOnlyImagesAllowed: false,
  browseFilesDescription: undefined,
  browseFilesTheme: BROWSE_FILES_THEME.DEFAULT,
  className: PropTypes.string,
  onDismiss: undefined,
  assetId: undefined,
  assetTitle: undefined,
  isAIGenerated: undefined,
  dropTitleText: 'Drag & drop your file',
  onDismissDropzone: undefined,
}
export default withBrowserInformation(React.memo(FileUploadContainer))
