import React, { useMemo, useState, useRef, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import UploadIcon from '_assets/icons/upload-icon.svg'
import PencilIcon from '_assets/icons/pencil.svg'
import PreviewIcon from '_assets/icons/ic_preview.svg'
import RepositionImageIcon from '_assets/icons/reposition-image.svg'
import ScreenshotIcon from '_assets/icons/screenshot.svg'
import ThumbnailReposition from '_components/image-reposition'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import MoreActions from '_components/more-actions'
import FileUploadContainer from '_components/file-upload-container'
import { BROWSE_FILES_THEME } from '_components/file-upload-container/browse-files'
import useToggle from '_hooks/use-modal'
import { Asset as AssetModel } from '_models'
import { editPresentationAsset, EDIT_ASSET } from '_modules/assets/actions'
import { getAssetType } from '_utils/asset'
import { PRESENTATION_STYLE_BARCELONA } from '_constants/presentation-styles'
import { presentationSelector } from '_modules/presentations/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import HideThumbAsset from '_assets/ic-hide-asset-thumb.png'
import OpacityIcon from '_assets/icons/ic-opacity.svg'
import OpacityChanger, { OPACITY_DEFAULT_VALUE } from '_components/opacity-changer'
import { getPlainText } from '_components/text-editor/utils'
import Svg from '_components/svg'

import styles from './styles.css'

const AssetFileContainer = ({
  className,
  asset,
  presentationId,
  onChange,
  onPreview,
  onToggleChangeImage,
  isAIGenerated,
  onChangeCover,
  onRepositionCover,
  classNameWrapper,
}) => {
  const [isRepositionCoverOpen, onToggleRepositionCover] = useToggle()
  const [isOpacityChangeOpen, onToggleOpacityChange] = useToggle()
  const [opacity, setOpacity] = useState(asset.get('backgroundOpacity') || OPACITY_DEFAULT_VALUE)

  const [isChangeCoverImageOpen, onToggleChangeCoverImage] = useToggle()

  const [width, setWidth] = useState()
  const [height, setHeight] = useState()

  const contentRef = useRef(null)

  const presentation = useSelector(state => presentationSelector(state, presentationId))

  const dispatch = useDispatch()

  const handleToggleChangeCoverImage = useCallback(() => {
    onToggleChangeCoverImage()
    if (onToggleChangeImage) {
      onToggleChangeImage(true)
    }
  }, [onToggleChangeCoverImage, onToggleChangeImage])

  const moreActions = useMemo(
    () => [
      {
        onSelect: handleToggleChangeCoverImage,
        label: 'Change',
        icon: UploadIcon,
      },
      {
        onSelect: onToggleRepositionCover,
        label: 'Reposition image',
        icon: RepositionImageIcon,
      },
      {
        onSelect: () => onToggleOpacityChange(),
        label: 'Change brightness',
        icon: OpacityIcon,
      },
    ],
    [handleToggleChangeCoverImage, onToggleOpacityChange, onToggleRepositionCover]
  )

  const renderAssetThumbnail = useMemo(() => {
    const whiteOpacity = opacity - 0.4 < 0 ? 0 : opacity - 0.4

    const overlay = `linear-gradient(180deg, rgba(0, 0, 0,${opacity}) 0%, rgba(0, 0, 0,${whiteOpacity}) 134.8%)`

    return {
      backgroundImage: asset.get('isHidden')
        ? `${overlay}, url(${HideThumbAsset})`
        : `${overlay}, url(${asset.get('thumbnailCropped') || asset.get('thumbnail')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    }
  }, [asset, opacity])

  const thumbnailMeta = useMemo(
    () =>
      asset && asset.get('thumbnailMeta') && asset.get('thumbnailMeta').size
        ? {
            position: asset
              .get('thumbnailMeta')
              .get('position')
              .toJS(),
            scale: asset.get('thumbnailMeta').get('scale'),
          }
        : { position: undefined, scale: undefined },
    [asset]
  )

  const handleRepositionCover = useCallback(
    ({ blob, position, scale, name }) => {
      onRepositionCover({ blob, position, scale, name })

      onToggleRepositionCover()
    },
    [onToggleRepositionCover, onRepositionCover]
  )

  const handleSelectFile = useCallback(
    file => {
      onChangeCover(file)
      if (onToggleChangeImage) {
        onToggleChangeImage(false)
      }
    },
    [onToggleChangeImage, onChangeCover]
  )

  const onOpacitySave = useCallback(
    newOpacity => {
      setOpacity(newOpacity)
      dispatch(
        editPresentationAsset({
          id: asset.get('id'),
          presentationId,
          params: {},
          payload: { backgroundOpacity: newOpacity },
        })
      )
      onToggleOpacityChange()
    },
    [asset, dispatch, onToggleOpacityChange, presentationId]
  )

  const [isLoading] = useFetchCall(EDIT_ASSET)

  const disMiss = useCallback(() => {
    if (onToggleChangeImage) {
      onToggleChangeImage(false)
    }
    onToggleChangeCoverImage()
  }, [onToggleChangeCoverImage, onToggleChangeImage])

  useEffect(() => {
    if (contentRef.current) {
      setWidth(contentRef.current.offsetWidth)
      setHeight(contentRef.current.offsetHeight)
    }
  }, [isLoading])

  const renderContent = useMemo(() => {
    if (isOpacityChangeOpen) {
      return (
        <OpacityChanger
          className={styles['scale-control']}
          cover={asset.get('thumbnailCropped') || asset.get('thumbnail')}
          toggleOpacityChange={onToggleOpacityChange}
          handleOpacitySave={onOpacitySave}
          opacity={opacity}
          setOpacity={setOpacity}
        />
      )
    }

    if (isChangeCoverImageOpen) {
      return (
        <FileUploadContainer
          hasUnsplashOption
          onlyBrowseFiles
          isOnlyImagesAllowed
          className={styles['browse-files']}
          browseFilesDescription="Replace cover image?"
          browseFilesTheme={BROWSE_FILES_THEME.LIGHT}
          onSelectFiles={handleSelectFile}
          onDismiss={disMiss}
          assetTitle={getPlainText(asset.get('title'))}
          isAIGenerated={isAIGenerated}
        />
      )
    }

    if (isRepositionCoverOpen) {
      return (
        <ThumbnailReposition
          avatarEditorClassName={styles['avatar-editor']}
          imageOverlayClassName={styles['image-overlay']}
          scaleControlClassName={styles['scale-control']}
          getHeight={height}
          getWidth={width}
          image={asset.get('thumbnail') || asset.get('thumbnailCropped')}
          initialPosition={thumbnailMeta.position}
          initialScale={thumbnailMeta.scale}
          onCancel={onToggleRepositionCover}
          onSave={handleRepositionCover}
        />
      )
    }

    return (
      <div
        className={classnames(
          className,
          styles['file-wrapper'],
          styles[
            getAssetType({
              filename: asset.get('filename'),
              type: asset.get('type'),
            })
          ],
          {
            [styles['barcelona-style']]: presentation.get('style') === PRESENTATION_STYLE_BARCELONA,
          }
        )}
        style={renderAssetThumbnail}
      >
        {presentation.get('style') === PRESENTATION_STYLE_BARCELONA && (
          <span className={styles.type}>
            {getAssetType({
              filename: asset.get('filename'),
              type: asset.get('type'),
            }).toUpperCase()}
          </span>
        )}
        {!asset.get('isHidden') && (
          <React.Fragment>
            <MoreActions
              className={styles['more-actions']}
              title="More actions"
              options={moreActions}
            >
              <div className={styles['more-actions-wrapper']}>
                <Svg icon={ScreenshotIcon} className={styles['more-actions-icon']} />
                Edit cover
              </div>
            </MoreActions>
            <Button
              className={styles['change-button']}
              startIcon={PencilIcon}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              onClick={onChange}
              size={BUTTON_SIZE.SMALL}
            >
              Change
            </Button>
            <Button
              className={styles['preview-button']}
              startIcon={PreviewIcon}
              theme={BUTTON_THEME.TRANSPARENT_FILL}
              onClick={onPreview}
              size={BUTTON_SIZE.SMALL}
            >
              Preview
            </Button>
          </React.Fragment>
        )}
      </div>
    )
  }, [
    isOpacityChangeOpen,
    isChangeCoverImageOpen,
    isRepositionCoverOpen,
    className,
    asset,
    presentation,
    renderAssetThumbnail,
    moreActions,
    onChange,
    onPreview,
    onToggleOpacityChange,
    onOpacitySave,
    opacity,
    disMiss,
    isAIGenerated,
    height,
    width,
    thumbnailMeta.position,
    thumbnailMeta.scale,
    onToggleRepositionCover,
    handleRepositionCover,
    handleSelectFile,
  ])

  return (
    <div ref={contentRef} className={classNameWrapper}>
      {renderContent}
    </div>
  )
}

AssetFileContainer.propTypes = {
  asset: PropTypes.instanceOf(AssetModel).isRequired,
  className: PropTypes.string,
  presentationId: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  onPreview: PropTypes.func.isRequired,
  onToggleChangeImage: PropTypes.func,
  isAIGenerated: PropTypes.string,
  onRepositionCover: PropTypes.func.isRequired,
  onChangeCover: PropTypes.func.isRequired,
  classNameWrapper: PropTypes.string,
}

AssetFileContainer.defaultProps = {
  className: null,
  isAIGenerated: undefined,
  classNameWrapper: null,
  onToggleChangeImage: null,
}

export default React.memo(AssetFileContainer)
