import React, { Fragment, useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import Dropzone from 'react-dropzone'
import { browserHistory } from 'react-router'
import PropTypes from 'prop-types'
import debounce from 'lodash.debounce'
import classnames from 'classnames'
import { Map } from 'immutable'

import PlusIcon from '_assets/icons/plus.svg'
import ViewIcon from '_assets/icons/view.svg'
import {
  FormAsset,
  StandardAsset,
  SeparatorAsset,
  TextAsset,
  FullImageAsset,
} from '_components/asset'
import Button, { BUTTON_THEME, BUTTON_SIZE } from '_components/ui-kit/button'
import WysiwygTextfield from '_components/wysiwyg-textfield'
import { ALERT_TYPE } from '_components/toast'
import { Presentation } from '_models'
import { openToasterAlert } from '_modules/toaster-alert/actions'
import { updatePresentation, setPendingAssets } from '_modules/presentations/actions'
import {
  createPresentationAsset,
  editPresentationAsset,
  reorderPresentationAssets,
  CREATE_ASSET,
  REORDER_ASSETS,
  EDIT_ASSET,
  editAssetUploadSystemV2,
  EDIT_ASSET_UPLOAD_SYSTEM_V2,
} from '_modules/assets/actions'
import {
  getPresentationAssetsSelector,
  getCurrentAssetIndexSelector,
} from '_modules/assets/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import useToggle from '_hooks/use-modal'
import { ASSET_TYPE } from '_constants/presentation'
import DragAndDropModal from '_components/drag-and-drop-modal'
import { ALL_TYPES_EXTENSIONS } from '_utils/files'
import { SelectAssetType } from '_components/asset/components/select-asset-type'
import useSendFile from '_hooks/use-send-file'

import styles from './styles.css'

const DELETE_COUNT = {
  START_INDEX: 1,
  END_INDEX: 0,
}

const PresentationAssetsSection = ({ presentation, isAIGenerated }) => {
  const [state, setState] = useState(Map({}))

  const [droppedFiles, setDroppedFiles] = useState([])

  const [isDropzoneActive, onToggleDropzoneActive] = useToggle()

  const [isDragAndDropModalOpen, onToggleDragAndDropModal] = useToggle()

  const [isChangeImageOpen, onToggleChangeImage] = useState(false)

  const dispatch = useDispatch()

  const { sendFile } = useSendFile()

  const assets = useSelector(getPresentationAssetsSelector)
  const currentAssetIndex = useSelector(getCurrentAssetIndexSelector)

  const hasAssetEmpty = useMemo(
    () =>
      !!assets.filter(
        asset =>
          asset &&
          !asset.get('thumbnail') &&
          !asset.get('thumbnailCropped') &&
          !asset.get('thumbnailScreenshot') &&
          asset.get('type') !== 'form' &&
          asset.get('type') !== 'separator'
      ).size,
    [assets]
  )

  const lastAssetIndex = useMemo(() => assets.size - 1, [assets.size])

  const handleReorderAssets = useCallback((list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, DELETE_COUNT.START_INDEX)
    result.splice(endIndex, DELETE_COUNT.END_INDEX, removed)

    return result
  }, [])

  const onReorderAssets = useCallback(
    (startIndex, endIndex) => {
      const reorderedAssets = handleReorderAssets(assets, startIndex, endIndex)
      const params = presentation.get('template')
        ? { template: presentation.get('template') }
        : null

      dispatch(
        reorderPresentationAssets({
          assets: reorderedAssets,
          params,
          presentationId: presentation.get('id'),
        })
      )
    },
    [handleReorderAssets, assets, presentation, dispatch]
  )

  const onDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return
      }

      onReorderAssets(result.source.index, result.destination.index)
    },
    [onReorderAssets]
  )

  const handleSendFileV2System = useCallback(() => {
    if (droppedFiles.length) {
      const newAssets = assets.slice(assets.size - droppedFiles.length)

      newAssets.forEach((asset, index) => {
        const payload = {
          keepCurrentThumbnail: false,
        }
        sendFile(asset, presentation.get('id'), droppedFiles[index], payload)
      })
    }
  }, [assets, droppedFiles, presentation, sendFile])

  useFetchCall(EDIT_ASSET_UPLOAD_SYSTEM_V2, handleSendFileV2System)

  const onReorderSuccess = useCallback(() => {
    if (droppedFiles) {
      const newAssets = assets.slice(assets.size - droppedFiles.length)

      newAssets.forEach((asset, index) => {
        const payload = {
          filename: droppedFiles[index].name,
          useV2: true,
        }

        dispatch(
          editAssetUploadSystemV2({
            presentationId: presentation.get('id'),
            id: asset.get('id'),
            payload,
          })
        )
      })
    }
  }, [assets, dispatch, droppedFiles, presentation])

  const onReorderReject = useCallback(() => {
    dispatch(
      openToasterAlert({
        type: ALERT_TYPE.ERROR,
        message: 'Something went wrong.',
      })
    )
  }, [dispatch])

  const onCreatePresentationAssetSuccess = useCallback(() => {
    if (!Number.isInteger(currentAssetIndex) && droppedFiles.length) {
      const newAssets = assets.slice(assets.size - droppedFiles.length)

      newAssets.forEach((asset, index) => {
        dispatch(
          editPresentationAsset({
            id: asset.get('id'),
            presentationId: presentation.get('id'),
            params: {},
            payload: { file: droppedFiles[index], type: ASSET_TYPE.FILE },
            isUploadingFile: true,
          })
        )
      })

      setDroppedFiles([])
    }
    if (Number.isInteger(currentAssetIndex)) {
      const startIndex = assets.size - 1
      const endIndex = currentAssetIndex

      onReorderAssets(startIndex, endIndex)
    }
  }, [currentAssetIndex, droppedFiles, assets, onReorderAssets, dispatch, presentation])

  const onCreatePresentationAssetReject = useCallback(() => {
    setDroppedFiles([])
  }, [])

  const [isLoadingCreateAsset] = useFetchCall(
    CREATE_ASSET,
    onCreatePresentationAssetSuccess,
    onCreatePresentationAssetReject
  )

  const handleErrorOfEdit = useCallback(() => {
    if (Number.isInteger(currentAssetIndex)) {
      const startIndex = assets.size - 1
      const endIndex = currentAssetIndex

      onReorderAssets(startIndex, endIndex)
    }
  }, [currentAssetIndex, assets, onReorderAssets])

  useFetchCall(EDIT_ASSET, () => {}, handleErrorOfEdit)
  const [isLoadingReorderAssets] = useFetchCall(REORDER_ASSETS, onReorderSuccess, onReorderReject)

  const onAddAsset = useCallback(
    event => {
      if (isLoadingCreateAsset) return

      const assetIndex =
        event && event.target.dataset.index ? event.target.dataset.index : undefined

      dispatch(
        createPresentationAsset({
          presentationId: presentation.get('id'),
          currentAssetIndex: assetIndex,
        })
      )
    },
    [dispatch, isLoadingCreateAsset, presentation]
  )

  const onPreviewAsset = useCallback(
    () => browserHistory.push(`/presentations/preview/${presentation.get('id')}`),
    [presentation]
  )

  const handleInputChange = useCallback(
    debounce(
      (name, value) => dispatch(updatePresentation(presentation.get('id'), { [name]: value })),
      300
    ),
    []
  )

  const onInputChange = useCallback(
    ({ name, value, plainText }) => {
      if (plainText) {
        handleInputChange(name, value)
        return
      }

      handleInputChange(name, plainText)
    },
    [handleInputChange]
  )

  const onDragEnter = useCallback(() => {
    if (!hasAssetEmpty && !isDropzoneActive) {
      onToggleDropzoneActive()
    }
  }, [hasAssetEmpty, isDropzoneActive, onToggleDropzoneActive])

  const onDragLeave = useCallback(() => {
    if (isDropzoneActive) {
      onToggleDropzoneActive()
    }
  }, [isDropzoneActive, onToggleDropzoneActive])

  const onDrop = useCallback(() => {
    droppedFiles.forEach((_, index) => {
      const newIndex = lastAssetIndex + index + 1
      dispatch(
        createPresentationAsset({
          presentationId: presentation.get('id'),
          currentAssetIndex: newIndex,
          payload: { type: ASSET_TYPE.FILE },
        })
      )
    })

    onToggleDragAndDropModal()
  }, [dispatch, droppedFiles, lastAssetIndex, onToggleDragAndDropModal, presentation])

  const onToggleDrop = useCallback(
    files => {
      if (isDropzoneActive) {
        onToggleDropzoneActive()
      }
      if (isDropzoneActive && files.length) {
        setDroppedFiles(files)
        onToggleDragAndDropModal()
      }
    },
    [isDropzoneActive, onToggleDragAndDropModal, onToggleDropzoneActive]
  )

  const handleDropRejected = useCallback(
    file => {
      if (file.some(item => item.type.includes('video'))) {
        const message =
          'Please host your video on a platform like YouTube or Vimeo and provide an accessible link for uploading!'

        dispatch(
          openToasterAlert({
            type: ALERT_TYPE.ERROR,
            message,
          })
        )
      }
    },
    [dispatch]
  )

  const handlePaste = useCallback(
    event => {
      if (
        event.clipboardData &&
        event.clipboardData.files &&
        event.clipboardData.files.length &&
        !isChangeImageOpen &&
        !hasAssetEmpty
      ) {
        const fileObject = event.clipboardData.files[0]
        if ([fileObject].length) {
          setDroppedFiles([fileObject])
          onToggleDragAndDropModal()
        }
      }
    },
    [hasAssetEmpty, isChangeImageOpen, onToggleDragAndDropModal]
  )

  const handleAssetInputChange = useCallback(
    (assetId, name, value) => {
      setState(state.setIn([assetId], { ...state.get(assetId), [name]: value }))
      dispatch(setPendingAssets(presentation.get('id'), true))
    },
    [state, setState, dispatch, presentation]
  )

  useEffect(() => {
    let updateAssetTimer
    if (state.size) {
      updateAssetTimer = setInterval(() => {
        state.map((item, key) =>
          dispatch(
            editPresentationAsset({
              id: key,
              presentationId: presentation.get('id'),
              params: {},
              payload: { ...item },
            })
          )
        )
        setState(Map({}))
        dispatch(setPendingAssets(presentation.get('id'), false))
      }, 2000)
    }

    return () => {
      if (updateAssetTimer) clearInterval(updateAssetTimer)
    }
  }, [dispatch, presentation, state])

  const renderAsset = useCallback(
    ({ assetType, index, asset, presentationId }) => {
      const [type] = assetType ? assetType.split('_') : ['']

      // Old assets have no type but have a title or description field so we can assume they are of type link or file
      const isOldAsset = !type && !!(asset.get('description') || asset.get('title'))

      if (type === ASSET_TYPE.CONTACT) {
        return <FormAsset index={index} asset={asset} presentationId={presentationId} />
      }
      if (type === ASSET_TYPE.SEPARATOR) {
        return <SeparatorAsset index={index} asset={asset} presentationId={presentationId} />
      }

      if (type === ASSET_TYPE.TEXT) {
        return (
          <TextAsset
            asset={asset}
            presentationId={presentationId}
            handleAssetInputChange={handleAssetInputChange}
            index={index}
          />
        )
      }

      if (type === ASSET_TYPE.IMAGE) {
        return <FullImageAsset asset={asset} presentationId={presentationId} index={index} />
      }

      if (
        isOldAsset ||
        type === ASSET_TYPE.LINK ||
        type === ASSET_TYPE.FILE ||
        type === ASSET_TYPE.VIDEO
      ) {
        return (
          <StandardAsset
            assetId={asset.get('id')}
            index={index}
            presentationId={presentationId}
            onToggleChangeImage={onToggleChangeImage}
            isAIGenerated={isAIGenerated}
            handleAssetInputChange={handleAssetInputChange}
          />
        )
      }

      return <SelectAssetType index={index} asset={asset} presentationId={presentationId} />
    },
    [isAIGenerated, handleAssetInputChange]
  )

  return (
    <Dropzone
      multiple
      disableClick
      onDrop={onToggleDrop}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      accept={ALL_TYPES_EXTENSIONS}
      className={styles['drop-zone']}
      onDropRejected={handleDropRejected}
      onPaste={handlePaste}
    >
      {isDropzoneActive && (
        <div className={styles.overlay}>
          <p className={styles['dropzone-text']}>Drop your files here</p>
        </div>
      )}
      <div className={styles['assets-wrapper']}>
        <WysiwygTextfield
          isMultiline
          className={classnames(styles['text-area'], styles['optional-message'])}
          inputClassName={styles['text-area-input']}
          name="openingMsg"
          placeholder="Include an opening message (optional)"
          value={presentation.get('openingMsg')}
          onChange={onInputChange}
          presentationId={presentation.id}
        />
        <div className={styles['assets-content']}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {assets.map((asset, index) => (
                    <Fragment key={`asset-${asset.get('id')}`}>
                      <div className={styles['entry-container']}>
                        <div className={styles.line} />
                        <Button
                          data-index={index}
                          className={styles['new-entry-button']}
                          startIcon={PlusIcon}
                          onClick={onAddAsset}
                          isLoading={isLoadingCreateAsset || isLoadingReorderAssets}
                          disabled={isLoadingCreateAsset || isLoadingReorderAssets}
                          size={BUTTON_SIZE.SMALL}
                        >
                          ADD ASSET
                        </Button>
                      </div>
                      {renderAsset({
                        assetType: asset.get('type'),
                        index,
                        asset,
                        presentationId: presentation.get('id'),
                      })}
                    </Fragment>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <div className={styles['assets-actions']}>
            <div className={styles.line} />
            <Button
              className={styles['assets-actions-button']}
              startIcon={PlusIcon}
              onClick={onAddAsset}
              isLoading={isLoadingCreateAsset}
              disabled={isLoadingCreateAsset}
            >
              ADD ASSET
            </Button>
            <Button
              className={styles['preview-button']}
              startIcon={ViewIcon}
              theme={BUTTON_THEME.PRIMARY}
              onClick={onPreviewAsset}
              isLoading={isLoadingCreateAsset}
              disabled={isLoadingCreateAsset}
            >
              PREVIEW
            </Button>
          </div>
        </div>
        <WysiwygTextfield
          isMultiline
          className={styles['text-area']}
          inputClassName={styles['text-area-input']}
          name="closingMsg"
          placeholder="Conclude with a closing statement (optional)"
          value={presentation.get('closingMsg')}
          onChange={onInputChange}
          presentationId={presentation.id}
        />
      </div>
      {isDragAndDropModalOpen && (
        <DragAndDropModal
          isOpen
          dismiss={onToggleDragAndDropModal}
          dragAndDropAction={onDrop}
          droppedFiles={droppedFiles.length}
        />
      )}
    </Dropzone>
  )
}

PresentationAssetsSection.propTypes = {
  presentation: PropTypes.instanceOf(Presentation).isRequired,
  isAIGenerated: PropTypes.string,
}

PresentationAssetsSection.defaultProps = {
  isAIGenerated: undefined,
}

export default React.memo(PresentationAssetsSection)
