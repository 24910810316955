import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Map } from 'immutable'
import { useDispatch } from 'react-redux'

import Asset from '_models/asset'
import FileUploadContainer from '_components/file-upload-container'
import { BROWSE_FILES_THEME } from '_components/file-upload-container/browse-files'
import {
  editAssetUploadSystemV2,
  EDIT_ASSET_UPLOAD_SYSTEM_V2,
  clearProgress,
  editPresentationAsset,
  ASSET_FILE_UPLOAD_CONVERT,
} from '_modules/assets/actions'
import useFetchCall from '_hooks/use-fetch-call'
import useToggle from '_hooks/use-modal'
import PreviewAssetModal from '_components/preview-asset-modal-new'
import useSendFile from '_hooks/use-send-file'

import HideAsset from '../components/hide-asset'
import AssetMoreActions from '../components/asset-more-actions'
import AssetProgressBar from '../standard-asset/asset-progress-bar'
import DraggableWrapper from '../components/draggable-wrapper'
import AssetFileContainer from '../standard-asset/asset-file-container'
import AssetTitle from '../components/asset-header'

import styles from './styles.css'

export const FullImageAsset = ({ index, asset, presentationId }) => {
  const { sendFile } = useSendFile()
  const [isPreviewAssetModalOpen, onTogglePreviewAssetModal] = useToggle()
  const [isChangeFile, onToggleIsChangeFile] = useToggle()
  const [state, setState] = useState({
    file: null,
    thumbnailMeta: null,
    isThumbnailOnly: false,
  })
  const dispatch = useDispatch()

  const clearState = useCallback(() => {
    if (isChangeFile) {
      onToggleIsChangeFile(false)
    }
  }, [onToggleIsChangeFile, isChangeFile])

  useFetchCall(ASSET_FILE_UPLOAD_CONVERT, clearState)

  const handleSendFile = useCallback(() => {
    const payload = {
      keepCurrentThumbnail: false,
    }
    const fields = asset.get('fields')
    if (fields) {
      sendFile(asset, presentationId, state.file, payload)
    }
  }, [asset, state.file, presentationId, sendFile])

  useFetchCall(EDIT_ASSET_UPLOAD_SYSTEM_V2, handleSendFile)

  const handleOnSelect = useCallback(
    file => {
      setState(prevState => ({
        ...prevState,
        file,
      }))

      const payload = {
        filename: file.name,
        useV2: true,
      }

      dispatch(
        editAssetUploadSystemV2({
          presentationId,
          id: asset.get('id'),
          payload,
        })
      )
    },
    [presentationId, asset, dispatch]
  )

  const onResend = useCallback(() => {
    dispatch(clearProgress(asset.get('id')))
    onToggleIsChangeFile(true)
  }, [asset, dispatch, onToggleIsChangeFile])

  const onRepositionCover = useCallback(
    ({ blob, position, scale, name }) => {
      const payloadV2 = {
        filename: name,
        useV2: true,
        isThumbnailCropped: true,
      }
      setState(prevState => ({
        ...prevState,
        file: blob,
        thumbnailMeta: {
          position,
          scale,
        },
      }))

      dispatch(
        editAssetUploadSystemV2({
          presentationId,
          id: asset.get('id'),
          payload: payloadV2,
        })
      )
    },
    [asset, dispatch, presentationId]
  )

  const onChangeCover = useCallback(
    file => {
      const payloadV2 = {
        filename: asset.get('thumbnail'),
        use_v2: true,
        is_thumbnail_only: true,
      }

      setState(prevState => ({
        ...prevState,
        isThumbnailOnly: true,
        file,
      }))

      dispatch(
        editAssetUploadSystemV2({
          presentationId,
          id: asset.get('id'),
          payload: payloadV2,
        })
      )
    },
    [asset, dispatch, presentationId]
  )

  const handleDismissDropzone = useCallback(() => {
    onToggleIsChangeFile(false)
    if (!asset.get('thumbnail')) {
      dispatch(
        editPresentationAsset({
          id: asset.get('id'),
          presentationId,
          payload: { type: '' },
        })
      )
    }
  }, [onToggleIsChangeFile, asset, dispatch, presentationId])

  const renderAsset = useMemo(() => {
    if (
      asset.get('percentageUpload') > 0 ||
      asset.get('percentageUpload') < 100 ||
      asset.get('hasError')
    ) {
      return (
        <AssetProgressBar
          assetId={asset.get('id')}
          className={styles['asset-progress-bar']}
          progressValue={asset.get('percentageUpload')}
          onResend={onResend}
        />
      )
    }

    if (asset.get('thumbnailCropped') || (asset.get('thumbnail') && !isChangeFile)) {
      return (
        <div className={styles.preview}>
          <AssetFileContainer
            classNameWrapper={styles['image-asset-preview']}
            asset={asset}
            presentationId={presentationId}
            className={styles['image-asset-preview-thumbnail']}
            onPreview={onTogglePreviewAssetModal}
            onRepositionCover={onRepositionCover}
            onChangeCover={onChangeCover}
            onChange={onToggleIsChangeFile}
          />
          <AssetMoreActions
            asset={asset}
            className={styles['asset-actions']}
            presentationId={presentationId}
          />
        </div>
      )
    }

    return (
      <div
        className={classnames(styles.container, {
          [styles.hidden]: asset.get('isHidden'),
        })}
      >
        {asset.get('isHidden') && <HideAsset asset={asset} presentationId={presentationId} />}
        {!isChangeFile && (
          <AssetTitle
            type={asset.get('type')}
            assetId={asset.get('id')}
            presentationId={presentationId}
          />
        )}
        <FileUploadContainer
          hasUnsplashOption
          className={classnames(styles['browse-files'])}
          browseFilesTheme={BROWSE_FILES_THEME.LIGHT}
          onSelectFiles={handleOnSelect}
          assetId={asset.get('id')}
          isOnlyImagesAllowed
          dropTitleText="Drop your image here"
          browseFilesDescription="Upload your image from:"
          onDismiss={handleDismissDropzone}
          onDismissDropzone={handleDismissDropzone}
        />
        <AssetMoreActions
          asset={asset}
          className={styles['asset-actions']}
          presentationId={presentationId}
        />
      </div>
    )
  }, [
    asset,
    presentationId,
    handleOnSelect,
    onResend,
    onRepositionCover,
    onTogglePreviewAssetModal,
    onChangeCover,
    onToggleIsChangeFile,
    isChangeFile,
    handleDismissDropzone,
  ])

  return (
    <DraggableWrapper draggableId={asset.get('id')} index={index} presentationId={presentationId}>
      {renderAsset}
      {isPreviewAssetModalOpen && (
        <PreviewAssetModal
          assetId={asset.get('id')}
          presentationId={presentationId}
          onClose={onTogglePreviewAssetModal}
        />
      )}
    </DraggableWrapper>
  )
}

FullImageAsset.propTypes = {
  asset: PropTypes.oneOfType([PropTypes.instanceOf(Map), PropTypes.instanceOf(Asset)]).isRequired,
  presentationId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
}
